import React, { useState } from "react";
import {
  Row,
  Col,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
  Accordion,
} from "reactstrap";

type Props = {
  faqList: {
    title: string;
    desc: string;
  }[];
};

const FAQAccordion = ({ faqList }: Props) => {
  const [open, setOpen] = useState("1");

  const toggle = (id: any) => {
    if (open === id) {
      //@ts-ignore
      setOpen();
    } else {
      setOpen(id);
    }
  };
  return (
    <>
      {/* @ts-ignore */}
      <Accordion open={open} toggle={toggle}>
        <Row>
          {faqList?.map((f, i) => {
            return (
              <Col xs={12} md={6} key={i}>
                <AccordionItem className="rounded-0">
                  <AccordionHeader targetId={(i + 1).toString()}>
                    {f.title}
                  </AccordionHeader>
                  <AccordionBody accordionId={(i + 1).toString()}>
                    <p>{f.desc}</p>
                  </AccordionBody>
                </AccordionItem>
              </Col>
            );
          })}
        </Row>
      </Accordion>
    </>
  );
};

export default FAQAccordion;
