import { HelpArticleEntry } from "@/@types/help/Articles";
import {
  articleMapping,
  categoryMapping,
  collections,
} from "../help/help-articles-data";

export const getArticleEntries = () => {
  const articleEntries: HelpArticleEntry[] = [];

  collections.forEach((col, i) => {
    const { categories, articles: colArticles } = col;
    categories.forEach((cat) => {
      const category = categoryMapping[cat];
      const { articles: catArticles } = category;
      catArticles.forEach((art) => {
        const article = articleMapping[art];
        articleEntries.push({
          colIndex: i,
          catKey: cat,
          artKey: art,
          artTitle: article.title,
        });
      });
    });
  });

  return articleEntries;
};
