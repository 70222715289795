/* eslint-disable react/no-unescaped-entities */
import Link from "next/link";

import {
  ArticleKeys,
  CategoryKeys,
  CategoryType,
  CollectionType,
  HelpArticle,
  HelpArticleDirectorySubPage,
} from "@/@types/help/Articles";
import { helpImages } from "@/utils/images";
import { routes } from "@/utils/routes";

import technologyPartners from "@/data/technology-partner.json";
import fulfillmentPartners from "@/data/fulfillment-partner.json";
import FAQAccordion from "../utils/faq-accordion";
import { overview } from "@/utils/content";

const getDirectorySupPages = (
  partners: any[]
): HelpArticleDirectorySubPage[] => {
  return partners
    .filter(
      (p) => !Boolean(p.name && (p.name === "Elavon" || p.name === "Stripe"))
    )
    .map((p) => {
      const isBlocked = Boolean(p.comingSoon || p.isBlocked);
      const name = p?.name;
      return {
        name: name
          ? isBlocked
            ? name + "(Coming Soon)"
            : name
          : "Not Specified",
        path: isBlocked ? "#" : routes.partners + p.url,
        imageUrl: p.logoPath,
      } as HelpArticleDirectorySubPage;
    });
};

export const collections: CollectionType[] = [
  {
    title: "ShipVista Help",
    link: "shipvista-help",
    categories: ["manage-orders", "ship-orders", "manage-shipments"],
  },
  {
    title: "Integrations",
    link: "integrations",
    categories: ["store-integrations", "carrier-integrations"],
  },
  {
    title: "Workflows",
    link: "workflows",
    categories: ["user-settings"],
  },
  {
    title: "Guides & FAQs",
    link: "guides",
    categories: ["balance", "faq"],
  },
];

export const categoryMapping: { [k in CategoryKeys]: CategoryType } = {
  "carrier-integrations": {
    title: "Carrier Integrations",
    link: "carrier-integrations",
    articles: [
      "carrier-integrations-overview",
      "connect-carrier",
      "edit-services",
    ],
  },
  "store-integrations": {
    title: "Store Integrations",
    link: "store-integrations",
    articles: ["store-integrations-overview"],
  },
  "manage-orders": {
    title: "Manage Orders",
    link: "manage-orders",
    articles: [
      "connect-a-store",
      "create-manual-store",
      "edit-store-settings",
      "deactivate-store",
      "import-orders-store",
      "create-manual-orders",
      "view-orders",
      "filter-orders",
      "hold-cancel-orders",
      "split-orders",
      "combine-orders",
      "order-tags",
      "edit-order-details",
      "order-statuses",
    ],
  },
  "ship-orders": {
    title: "Ship Orders",
    link: "ship-orders",
    articles: [
      "configure-shipping",
      "shipping-presets",
      "batch-shipping",
      "mark-as-shipped",
    ],
  },
  "manage-shipments": {
    title: "Manage Shipments",
    link: "manage-shipments",
    articles: [
      "track-shipment",
      "getting-rates-with-address",
      "create-label-shipments",
      "end-of-day",
    ],
  },
  "user-settings": {
    title: "User Settings",
    link: "user-settings",
    articles: [
      "settings-overview",
      "my-profile",
      "payments",
      "offers-available",
      "transaction-history",
      "subscriptions",
      "address-book",
      "air-miles",
    ],
  },
  balance: {
    title: "Balance",
    link: "balance",
    articles: ["add-funds", "add-card"],
  },
  faq: {
    title: "FAQs",
    link: "faqs",
    articles: ["subscriptions-faq"],
  },
};

export const articleMapping: { [k in ArticleKeys]: HelpArticle } = {
  "connect-carrier": {
    title: "Connect Your Carrier Account",
    link: "connect-carrier",
    data: [
      {
        type: "info",
        about: (
          <>
            <p>
              ShipVista offers integrations with your favorite carrier to
              provide flexibility when shipping. Currently, we only support
              Canada Post and UPS carriers, but we are working on integrating
              with more carriers to allow more options.
            </p>
          </>
        ),
        data: [
          {
            name: "Connecting Your Carrier",
            subLink: "connect-your-carrier",
            content: (
              <>
                <ol className="procedure">
                  <li>
                    <p>
                      Ensure that you are logged into the app and can see the
                      main dashboard.
                    </p>
                  </li>
                  <li>
                    <p>
                      Click the setting icon at the top right hand corner of the
                      window to go to the settings pages.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["connect-store-settings-cog"]}
                      alt="step"
                      style={{
                        height: "auto",
                      }}
                    />
                  </li>
                  <li>
                    <p>
                      Click the 'Integrations' button on the left hand side
                      accordian to expand the integrations panel.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["connect-store-click-intergrations"]}
                      alt="step"
                      style={{
                        height: "auto",
                      }}
                    />
                  </li>
                  <li>
                    <p>
                      Select the 'Carrier Setup' option in the panel that opens.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["connect-carrier-select-carrier-setup"]}
                      alt="step"
                      style={{
                        height: "auto",
                      }}
                    />
                  </li>
                  <li>
                    <p>
                      Click the 'Connect Carrier Account' button near the top
                      right hand corner of the screen.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["connect-carrier-carrier-connect-button"]}
                      alt="step"
                      style={{
                        height: "auto",
                      }}
                    />
                  </li>
                </ol>
              </>
            ),
          },
          {
            name: "Specific Carrier Instructions",
            subLink: "specific-instructions",
            content: (
              <>
                <p>
                  The following instructions will be specific to the carrier
                  that you choose to connect. For this example, we will use UPS.
                </p>
                <ol className="procedure">
                  <li>
                    <p>
                      In the dialog that opens, select the UPS logo and a form
                      should open.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["connect-carrier-carrier-list"]}
                      alt="step"
                      style={{
                        height: "auto",
                      }}
                    />
                  </li>
                  <li>
                    <p>
                      In the form, enter the correct information and proceed by
                      clicking the 'Connect Account' button at the bottom of the
                      form.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["connect-carrier-ups-connect"]}
                      alt="step"
                      style={{
                        height: "auto",
                      }}
                    />
                  </li>
                  <p>
                    Note: You can find the specific instructions to connect each
                    carrier on the respective{" "}
                    <Link href={routes.fulfillmentPartners}>
                      Fulfillment Partner page
                    </Link>
                    .
                  </p>
                </ol>
              </>
            ),
          },
        ],
      },
    ],
  },
  "connect-a-store": {
    title: "Connect a Store",
    link: "connect-a-store",
    data: [
      {
        type: "info",
        about:
          "The first step in importing your orders into ShipVista is to connect your stores. The steps to connect each store will be different based on the specific channel, but there are some basic steps to follow that are listed below.",
        data: [
          {
            name: "Connecting Your Store",
            subLink: "connect-your-store",
            content: (
              <>
                <ol className="procedure">
                  <li>
                    <p>
                      Ensure that you are logged into the app and can see the
                      main dashboard.
                    </p>
                  </li>
                  <li>
                    <p>
                      Click the settings icon at the top right hand corner of
                      the window to go to the settings pages.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["connect-store-settings-cog"]}
                      alt="step"
                      style={{
                        height: "auto",
                      }}
                    />
                  </li>
                  <li>
                    <p>
                      Click the 'Integrations' button on the left hand side
                      accordian to expand the integrations panel.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["connect-store-click-intergrations"]}
                      alt="step"
                      style={{
                        height: "auto",
                      }}
                    />
                  </li>
                  <li>
                    <p>
                      Select the 'Store Setup' option in the panel that opens.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["connect-store-select-store-setup"]}
                      alt="step"
                      style={{
                        height: "auto",
                      }}
                    />
                  </li>
                  <li>
                    <p>
                      Click the 'Connect Store Or Marketplace' button near the
                      top right hand corner of the screen.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["connect-store-connect-store-button"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      In the dialog that opens, select the logo of the store
                      that you want to connect and a form should open.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["connect-store-select-woo"]}
                      alt="step"
                      style={{
                        height: "auto",
                      }}
                    />
                  </li>
                  <li>
                    <p>
                      In the form, enter the correct information and proceed by
                      clicking the 'Connect' button at the bottom of the form.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["connect-store-enter-details"]}
                      alt="step"
                      style={{
                        height: "auto",
                      }}
                    />
                  </li>
                </ol>
              </>
            ),
          },
          {
            name: "Specific Store Instructions",
            subLink: "specific-instructions",
            content: (
              <>
                <p>
                  The following instructions will be specific to the store or
                  marketplace that you choose to connect. For this example, we
                  will use WooCommerce.
                </p>
                <ol className="procedure">
                  <li>
                    <p>
                      You would be redirected to a page to login to WooCommerce.
                      Please login.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["connect-store-login-as-admin"]}
                      alt="step"
                      style={{
                        height: "auto",
                      }}
                    />
                  </li>
                  <li>
                    <p>
                      You will then be prompted to give certain permissions to
                      ShipVista. Click Approve.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["connect-store-woo-permission-page"]}
                      alt="step"
                      style={{
                        height: "auto",
                      }}
                    />
                  </li>
                  <li>
                    <p>
                      Back in the ShipVista app, your WooCommerce store should
                      be successfully added.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["connect-store-store-added-success"]}
                      alt="step"
                      style={{
                        height: "auto",
                      }}
                    />
                  </li>
                  <p>
                    Note: You can find the specific instructions to connect each
                    store on the respective{" "}
                    <Link href={routes.technologyPartner}>
                      Technology Partner page
                    </Link>
                    .
                  </p>
                </ol>
              </>
            ),
          },
        ],
      },
    ],
  },
  "create-manual-store": {
    title: "Create a Manual Store",
    link: "create-manual-store",
    data: [
      {
        type: "info",
        about:
          "If you plan to upload orders via a file or you occasionally need to add orders that do not import from a connected store, you can use a manual store.",
        data: [
          {
            name: "Creating a Manual Store",
            subLink: "creating",
            content: (
              <>
                <ol className="procedure">
                  <li>
                    <p>
                      Ensure that you are logged into the app and can see the
                      main dashboard.
                    </p>
                  </li>
                  <li>
                    <p>
                      Click the settings icon at the top right hand corner of
                      the window to go to the settings pages.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["connect-store-settings-cog"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Click the 'Integrations' button on the left hand side
                      accordion to expand the integrations panel.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["connect-store-click-intergrations"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Select the 'Store Setup' option in the panel that opens.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["connect-store-select-store-setup"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Click the 'Connect Store Or Marketplace' button near the
                      top right hand corner of the screen.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["connect-store-connect-store-button"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      (Optional) You can search for ShipVista in the search bar
                      of stores to connect.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={
                        helpImages[
                          "create-manual-store-search-shipvista-optional"
                        ]
                      }
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Select the ShipVista logo in the list of stores that
                      appear.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={
                        helpImages["create-manual-store-select-shipvista-logo"]
                      }
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      In the form, enter the nickname for the store and proceed
                      by clicking the 'Connect' button at the bottom of the
                      form.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["create-manual-store-input-and-connect"]}
                      alt="step"
                    />
                  </li>
                </ol>
              </>
            ),
          },
        ],
      },
    ],
  },
  "edit-store-settings": {
    title: "Edit Store Settings",
    link: "edit-store-settings",
    data: [
      {
        type: "info",
        about:
          "Once you create a manual store or connect an existing store, you may later decide to edit the store. We currently only allow editing the store nickname, but more options will be available in the future based on the store.",
        data: [
          {
            name: "Edit Name",
            subLink: "edit-name",
            content: (
              <>
                <ol className="procedure">
                  <li>
                    <p>
                      Ensure that you are logged into the app and can see the
                      main dashboard.
                    </p>
                  </li>
                  <li>
                    <p>
                      Click the settings icon at the top right hand corner of
                      the window to go to the settings pages.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["connect-store-settings-cog"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Click the 'Integrations' button on the left hand side
                      accordian to expand the integrations panel.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["connect-store-click-intergrations"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Select the 'Store Setup' option in the panel that opens.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["connect-store-select-store-setup"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      To change the nickname of the store, click the edit icon
                      next to the store name.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "100%" }}
                      src={helpImages["edit-store-settings-click-edit-icon"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Enter the new nickname that you want to give to the store.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "100%" }}
                      src={helpImages["edit-store-settings-enter-new-nickname"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Click the checkmark icon next to the input when you are
                      finished editing and the new nickname should saved.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{
                        width: "auto",
                        maxWidth: "100%",
                        marginBottom: "20px",
                      }}
                      src={helpImages["edit-store-settings-check-new-nickname"]}
                      alt="step"
                    />
                    <br />
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "100%" }}
                      src={helpImages["edit-store-settings-nickname-saved"]}
                      alt="step"
                    />
                  </li>
                </ol>
              </>
            ),
          },
        ],
      },
    ],
  },
  "deactivate-store": {
    title: "Deactivate a Store",
    link: "deactivate-store",
    data: [
      {
        type: "info",
        about:
          "If you want to prevent a store from being visible or set it to no longer import orders, you can choose to deactivate it.",
        data: [
          {
            name: "Deactivate Store",
            subLink: "deactivate",
            content: (
              <>
                <p>To make a store inactive:</p>
                <ol className="procedure">
                  <li>
                    <p>
                      Click the settings icon at the top right hand corner of
                      the window to go to the settings pages.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["connect-store-settings-cog"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Click the 'Integrations' button on the left hand side
                      accordion to expand the integrations panel.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["connect-store-click-intergrations"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Select the 'Store Setup' option in the panel that opens.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["connect-store-select-store-setup"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Click the switch next to the corresponding store that you
                      want to deactivate to set it to inactive.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "100%" }}
                      src={
                        helpImages["deactivate-store-click-deactivate-switch"]
                      }
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>The store should no longer be active.</p>
                  </li>
                </ol>
              </>
            ),
          },
        ],
      },
    ],
  },
  "import-orders-store": {
    title: "Import Orders from a Store",
    link: "import-orders-store",
    data: [
      {
        type: "info",
        about:
          "One of the ways that you can get orders imported into ShipVista is by importing them directly from a connected store that is supported. You could also import them through a file or by creating a manual order. Here are the steps to import orders from a connected store.",
        data: [
          {
            name: "Import Order Tool",
            subLink: "import-order",
            content: (
              <>
                <ol className="procedure">
                  <li>
                    <p>
                      Click the 'Sync All Orders' icon at the top right hand
                      corner of the window to open a popover that displays the
                      list of connected stores. The icon should look like a
                      refresh icon.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["import-orders-store-click-import"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Click the 'Update All Stores' button at the top of the
                      popover to update all the stores, or click the refresh
                      icon next to the store you want to be updated.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["import-orders-store-click-update-all"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      The orders from the store that were updated should now be
                      imported.
                    </p>
                  </li>
                </ol>
              </>
            ),
          },
        ],
      },
    ],
  },
  "create-manual-orders": {
    title: "Create Manual Orders",
    link: "create-manual-orders",
    data: [
      {
        type: "info",
        about: (
          <>
            <p>
              A manual order is an order that is created directly from within
              ShipVista. They are created under a manual store (create a manual
              store by following the instructions &nbsp;
              <Link
                href={`${routes.help}/shipvista-help/manage-orders/create-manual-store`}
              >
                here
              </Link>
              ). You can have multiple manual stores.
            </p>
          </>
        ),
        data: [
          {
            name: "Create Manual Orders",
            subLink: "create-orders",
            content: (
              <>
                <p>To create a manual order:</p>
                <ol className="procedure">
                  <li>
                    <p>
                      Start by going to the orders page after signing in to the
                      application to see all your orders. You click the Orders
                      tab to get to the orders page.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["click-orders-tab"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Click the 'Create A Manual Order' button near the top of
                      the page. A dialog should appear showing you a form to
                      fill.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["create-manual-orders-click-cmo-button"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Fill in the information for the recipient and the other
                      order details.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["create-manual-orders-fill-in-info"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      If there are multiple manual stores that you have created,
                      you can use the dropdown to select the store for that
                      order.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["create-manual-orders-dropdown-stores"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      You can add line items to the order near the bottom of the
                      form.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "700px" }}
                      src={helpImages["create-manual-orders-add-line-items"]}
                      alt="step"
                    />
                    <p>
                      Note: The application will still allow you to add line
                      items to the orders later on, so this step is optional.
                    </p>
                  </li>
                  <li>
                    <p>
                      When you are finished, you have two options to save the
                      order. (1) 'Save And Create New Order' - to save the order
                      and leave the form open to create a new order. (2) 'Save
                      Order' - to save the order and close the form.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["create-manual-orders-two-options"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      After saving the order, the order should now display in
                      the orders table.
                    </p>
                  </li>
                </ol>
              </>
            ),
          },
        ],
      },
    ],
  },
  "view-orders": {
    title: "View Orders",
    link: "view-orders",
    data: [
      {
        type: "info",
        about: (
          <>
            <p>
              With ShipVista, you can change how you view, list, and sort
              orders. These actions could be performed at the top and left sides
              of the orders pages. There is also a search feature for basic
              searches of orders to more advanced searches.
            </p>
          </>
        ),
        data: [
          {
            name: "Order Statuses",
            subLink: "order-status",
            content: (
              <>
                <p>
                  Orders are grouped according to their statuses. By default,
                  when you visit the Orders tab in the application, you will see
                  the orders that are awaiting shipment.
                </p>
                <p>
                  If the order is paid for and waiting to be shipped, it will
                  appear in the awaiting shipment table. Most orders will be
                  imported directly into this table also. Depending on the
                  connected store, the order will also import into the below
                  status.
                </p>

                <ul>
                  <li>Awaiting Payment</li>
                  <li>On Hold</li>
                  <li>Awaiting Shipment</li>
                  <li>Shipped</li>
                  <li>Canceled</li>
                </ul>

                <p>
                  The statuses for the orders in your store may not match the
                  ones used within ShipVista. ShipVista uses order statuses that
                  relate directly to the shipping workflow.
                </p>
              </>
            ),
          },
          {
            name: "Search Orders",
            subLink: "search-orders",
            content: (
              <>
                <p>
                  ShipVista allows you to search for orders that match a search
                  term using one of two ways; (1) Basic Search within the orders
                  table and (2) Advanced search on the sidebar.
                </p>
                <p>
                  Use the Basic Search to search for orders based on Recipient
                  Name, Order Item, SKU, Order Number, or other criteria. The
                  Basic Search feature will search for any fields with a value
                  containing the search term.
                </p>
                <img
                  className={`img-fluid`}
                  style={{ width: "auto" }}
                  src={helpImages["view-orders-basic-search"]}
                  alt="step"
                />
                <p>
                  Use the Advanced Search on the sidebar to search for orders
                  regardless of the status while combining filtering criteria
                  over the search results. The Advanced Search feature will also
                  search for any order with a value that contains the search
                  term.
                </p>
                <img
                  className={`img-fluid`}
                  style={{ marginBottom: "20px" }}
                  src={helpImages["view-orders-advanced-search-one"]}
                  alt="step"
                />
                <br />
                <img
                  className={`img-fluid`}
                  src={helpImages["view-orders-advanced-search-two"]}
                  alt="step"
                />
              </>
            ),
          },
          {
            name: "Sort Orders",
            subLink: "sort-orders",
            content: (
              <>
                <p>
                  Using the column headers, you can sort any order table in
                  descending or ascending order.
                </p>
                <p>
                  You can sort by Marketplace Order Number, Age, Order Date,
                  Marketplace, Quantity, Order Total, Recipient Name, and
                  Country.
                </p>
                <img
                  className={`img-fluid`}
                  style={{ width: "auto", maxWidth: "100%" }}
                  src={helpImages["view-orders-sort-general"]}
                  alt="step"
                />
                <p>
                  To sort by the column, click its title text to toggle sorting
                  between ascending and descending order (alphabetical,
                  chronological, or numerical).
                </p>
                <img
                  className={`img-fluid`}
                  style={{ width: "auto" }}
                  src={helpImages["view-orders-sort-specific"]}
                  alt="step"
                />
              </>
            ),
          },
          {
            name: "View Multi-Item Orders",
            subLink: "multi-item-orders",
            content: (
              <>
                <p>
                  Within the application, it is possible to view orders with
                  multiple items by looking at the Item Name and SKU.
                </p>
                <p>
                  An order with multiple items will have the value of (Multiple
                  Items) for the columns Item Name and Item SKU. You can view
                  the multiple items withing the order by clicking the
                  marketplace order number value to open the Order Details
                  dialog. The Quantity column will also display the number of
                  items within the column.
                </p>
                <img
                  className={`img-fluid`}
                  style={{ width: "auto", maxWidth: "100%" }}
                  src={helpImages["view-orders-multi-items-view"]}
                  alt="step"
                />
              </>
            ),
          },
        ],
      },
    ],
  },
  "filter-orders": {
    title: "Filter Orders",
    link: "filter-orders",
    data: [
      {
        type: "info",
        about: (
          <>
            <p>
              With filters, you can view orders that match certain criteria
              within any of the orders tables. You can also combine multiple
              filters to easily and quickly find orders that you might need to
              focus your attention on.
            </p>
          </>
        ),
        data: [
          {
            name: "Apply Filter",
            subLink: "apply-filter",
            content: (
              <>
                <p>To apply a filter:</p>
                <ol className="procedure">
                  <li>
                    <p>
                      Select any of the filters from the filter bar at the top
                      of the orders table. A popup should open under the title
                      text for the filter. Select 'Marketplace' to filter by
                      marketplaces, 'Store' to filter by store, and
                      'Destination' by destination, etc.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "100%" }}
                      src={helpImages["filter-orders-filters-general"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Select the criteria that you wish to filter. For example,
                      under the 'Destination' filter, you can choose to show
                      domestic or international to view only those corresponding
                      orders.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "100%" }}
                      src={helpImages["filter-orders-filters-specific"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Immediately after selecting the filter criteria, the
                      filter will apply to the orders in the table. You can set
                      multiple filters by repeating the first two steps.
                    </p>
                  </li>
                </ol>
              </>
            ),
          },
          {
            name: "Remove Filter",
            subLink: "remove-filter",
            content: (
              <>
                <p>
                  To remove a filter you have applied, you can click the title
                  text for the filter and select Remove Filter.
                </p>
                <img
                  className={`img-fluid`}
                  style={{ width: "auto", maxWidth: "100%" }}
                  src={helpImages["filter-orders-filters-specific-remove"]}
                  alt="step"
                />

                <p>
                  To remove all the applied filters, you can click the 'Clear
                  the Filters' button next to the filter bar.
                </p>
                <img
                  className={`img-fluid`}
                  style={{ width: "auto", maxWidth: "100%" }}
                  src={helpImages["filter-orders-filters-general-remove"]}
                  alt="step"
                />
              </>
            ),
          },
        ],
      },
    ],
  },
  "hold-cancel-orders": {
    title: "Hold and Cancel Orders",
    link: "hold-cancel-orders",
    data: [
      {
        type: "info",
        about: (
          <>
            <p>
              While managing your orders, you may want to put orders on hold to
              ensure that they are not shipped; or cancel orders within
              ShipVista to remove them from Awaiting Shipment.
            </p>
          </>
        ),
        data: [
          {
            name: "Put Orders On Hold",
            subLink: "hold-orders",
            content: (
              <>
                <p>
                  The Hold Order action, found in the action popover on the
                  awaiting shipment page, will put the selected orders on hold
                  until a specified date.
                </p>
                <h4>Hold an Order</h4>
                <hr />
                <ol className="procedure">
                  <li>
                    <p>
                      Select the orders you want to put on hold by clicking on
                      them in the orders table. A checkmark should appear next
                      to the selected orders.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "100%" }}
                      src={helpImages["hold-cancel-orders-select-orders-hold"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Click the 'Actions' button to open a popover and select
                      the 'Hold Orders' option from the list of actions.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "100%" }}
                      src={helpImages["hold-cancel-orders-click-hold-button"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      A dialog will open showing a list of the selected orders
                      with dates beside them.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "600px" }}
                      src={helpImages["hold-cancel-orders-hold-orders-dialog"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      You can click the title text Hold Until to set the Hold
                      Until date for all the selected orders.
                    </p>
                    <p>
                      You can also set the Hold Until date for a specific order
                      by clicking on the date beside the order.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "600px" }}
                      src={helpImages["hold-cancel-orders-hold-until-click"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      A date selection popup should open for you to select the
                      date to restore the selected held order. Select the
                      desired date.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["hold-cancel-orders-hold-until-calendar"]}
                      alt="step"
                    />
                    <p>
                      ShipVista will keep the orders on the On Hold status until
                      the Hold Until date.
                    </p>
                  </li>
                  <li>
                    <p>
                      Click the Hold Order(s) button to place the orders on
                      hold.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={
                        helpImages[
                          "hold-cancel-orders-click-hold-orders-button"
                        ]
                      }
                      alt="step"
                    />
                    <p>
                      ShipVista will move the selected orders to the On Hold
                      status, which you can find on the left-hand side menu.
                    </p>
                  </li>
                </ol>
                <h4>Manually Restore an Order</h4>
                <hr />
                <p>
                  To restore or manually move an order from on hold back to
                  awaiting shipment within ShipVista:
                </p>
                <ol className="procedure reset">
                  <li>
                    <p>
                      Go to the On Hold orders by clicking on the left-hand side
                      menu. Select the orders that you want to restore.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ marginBottom: "20px" }}
                      src={helpImages["hold-cancel-orders-navigate-on-hold"]}
                      alt="step"
                    />
                    <br />
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "100%" }}
                      src={
                        helpImages[
                          "hold-cancel-orders-select-orders-restore-hold"
                        ]
                      }
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Click the 'Actions' button to open a popover and select
                      the 'Restore Orders' option from the list of actions.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "100%" }}
                      src={
                        helpImages[
                          "hold-cancel-orders-click-restore-hold-button"
                        ]
                      }
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Another popover will open notifying you how many orders
                      will be restored.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "100%" }}
                      src={
                        helpImages["hold-cancel-orders-restore-hold-popover"]
                      }
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Click the 'Ok, Restore Order(s)' button to restore the
                      orders.
                    </p>
                    <p>
                      As mentioned in the popover, ShipVista will only move the
                      selected orders back to Unshipped (or awaiting shipment)
                      status and you may need to take further actions in your
                      online marketplace.
                    </p>
                  </li>
                </ol>
              </>
            ),
          },
          {
            name: "Cancel Orders",
            subLink: "cancel-orders",
            content: (
              <>
                <p>
                  The Cancel Orders action will move the orders to the canceled
                  status. This will only affect the status within ShipVista.
                </p>
                <h4>Cancel Orders</h4>
                <hr />
                <ol className="procedure reset">
                  <li>
                    <p>
                      Select the orders that you want to cancel by clicking on
                      them in the orders table. A checkmark should appear next
                      to the selected orders.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "100%" }}
                      src={helpImages["hold-cancel-orders-select-orders-hold"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Click the 'Actions' button to open a popover and select
                      the 'Cancel Orders' option from the list of actions.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "100%" }}
                      src={helpImages["hold-cancel-orders-click-cancel-button"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Another popover will open notifying you how many orders
                      will be canceled.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "100%" }}
                      src={helpImages["hold-cancel-orders-cancel-popover"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Click the 'Ok, Cancel Order(s)' button to cancel the
                      orders.
                    </p>
                    <p>
                      As mentioned in the popover, ShipVista will only move the
                      selected orders to the Canceled status and you may need to
                      take further actions in your online marketplace.
                    </p>
                  </li>
                </ol>
                <h4>Manually Restore an Order</h4>
                <hr />
                <p>
                  To restore or manually move an order from on canceled back to
                  awaiting shipment within ShipVista:
                </p>
                <ol className="procedure reset">
                  <li>
                    <p>
                      Go to the Canceled orders by click on the left-hand side
                      menu. Select the orders that you want to restore.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "100%" }}
                      src={
                        helpImages[
                          "hold-cancel-orders-select-orders-restore-cancel"
                        ]
                      }
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Click the 'Actions' button to open a popover and select
                      the 'Restore Orders' option from the list of actions.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "100%" }}
                      src={
                        helpImages[
                          "hold-cancel-orders-click-restore-cancel-button"
                        ]
                      }
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Another popover will open notifying you how many orders
                      will be restored.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={
                        helpImages["hold-cancel-orders-restore-cancel-popover"]
                      }
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Click the 'Ok, Restore Order(s)' button to restore the
                      orders.
                    </p>
                    <p>
                      As mentioned in the popover, ShipVista will only move the
                      selected orders back to Unshipped (or awaiting shipment)
                      status and you may need to take further actions in your
                      online marketplace.
                    </p>
                  </li>
                </ol>
              </>
            ),
          },
        ],
      },
    ],
  },
  "split-orders": {
    title: "Split Orders",
    link: "split-orders",
    data: [
      {
        type: "info",
        about: (
          <>
            <p>
              With the Split Orders option, you can split orders into multiple
              orders to ship separately. This could be useful if the items ship
              from different locations or for other purposes.
            </p>
          </>
        ),
        data: [
          {
            name: "How to Split Orders",
            subLink: "how-to-split",
            content: (
              <>
                <p>To split items on an order:</p>
                <ol className="procedure">
                  <li>
                    <p>
                      Select the order in the orders table by clicking on it.
                      You can only select one order to perform this action.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "100%" }}
                      src={helpImages["split-orders-select-orders-split"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Click the 'Other Actions' button to open a popover and
                      select the 'Split Order' option from the list of actions.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "100%" }}
                      src={helpImages["split-orders-select-split"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      A dialog will open allowing you to split the order into
                      multiple orders with the same properties but with
                      different items.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "600px" }}
                      src={helpImages["split-orders-split-dialog"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      The items of the original order will be listed with
                      numerical inputs beside each item to split their quantity.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "100%" }}
                      src={helpImages["split-orders-split-items"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Set the quantity of the item(s) you want to include in the
                      new order by using the minus (-) and plus (+) signs beside
                      each item.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["split-orders-split-calculated"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      As you update the quantity, the order total of the
                      original and the new order will be calculated.
                    </p>
                  </li>
                  <li>
                    <p>
                      After, you can enter the amount paid for the new order.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["split-orders-split-paid"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      When you are finished, click the 'Split Order' button to
                      split the order.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["split-orders-split-button"]}
                      alt="step"
                    />
                  </li>
                </ol>
              </>
            ),
          },
        ],
      },
    ],
  },
  "combine-orders": {
    title: "Combine Orders",
    link: "combine-orders",
    data: [
      {
        type: "info",
        about: (
          <>
            <p>
              When you want to combine multiple orders into a single order, you
              can use the 'Combine Orders' functionality.
            </p>
            <p>
              Combine Orders will combine properties including items, tags,
              custom declarations, and notes into a single order. This new order
              will replace the old orders within ShipVista.
            </p>
          </>
        ),
        data: [
          {
            name: "How to Combine Orders",
            subLink: "how-to-combine",
            content: (
              <>
                <p>To combine a set of orders:</p>
                <ol className="procedure">
                  <li>
                    <p>
                      Select the orders in the orders table by clicking on them.
                      You have to select more than one to perform this action.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["combine-orders-select-orders-combine"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Click the 'Other Actions' button to open a popover and
                      select the 'Combine Orders' option from the list of
                      actions.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["combine-orders-click-combine-orders"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      A dialog will open allowing you to combine the selected
                      orders.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["combine-orders-combine-dialog"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      The marketplace order number for each order will be
                      listed. Beside this number will be a notice informing you
                      which order will be the surviving order and which order(s)
                      will be absorbed by the surviving order. The new order
                      will have the same properties as the surviving order, such
                      as the marketplace order id, order date, etc.
                    </p>
                  </li>
                  <li>
                    <p>
                      You can click and drag each order in the list of orders
                      within the dialog to move the orders around. The first
                      order will always be the surviving order.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["combine-orders-combine-drag"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      When you are finished with the last step, click 'Combine'
                      to combine the orders into one.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["combine-orders-click-combine"]}
                      alt="step"
                    />
                  </li>
                </ol>
              </>
            ),
          },
        ],
      },
    ],
  },
  "order-tags": {
    title: "Order Tags",
    link: "order-tags",
    data: [
      {
        type: "info",
        about: (
          <>
            <p>
              Order Tags are a way of labeling orders so you can easily
              identify, sort, and categorize them. There is no limit to the
              number of order tags that can be created or applied to an order.
            </p>
            <p>Examples of some order tags you might use are:</p>
            <ul>
              <li>Medium Priority</li>
              <li>Important</li>
              <li>Insurance Required</li>
            </ul>
          </>
        ),
        data: [
          {
            name: "Create Tags",
            subLink: "create-tags",
            content: (
              <>
                <p>To create an order tag:</p>
                <ol className="procedure">
                  <li>
                    <p>Select an order in the orders table.</p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["order-tags-select-order-tags"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Click the 'Tags' button to open a popover containing a
                      list of existing order tags. If there are no existing
                      ones, this list will be empty.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["order-tags-click-tags-dropdown"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Select the 'Manage Tags' button at the top of the popover.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "200px" }}
                      src={helpImages["order-tags-click-manage-tags"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      A dialog will open allowing you to manage (create, edit,
                      or delete) tags. You may see a list of existing tags in
                      this dialog.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["order-tags-manage-tags-dialog"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Click the 'New Tag' button at the bottom of the dialog and
                      a entry should be added in the list of tags.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["order-tags-click-new-tag"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Enter the name of the tag in the text input and use the
                      color picker beside the input to select the color of the
                      tag.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{
                        width: "auto",
                        maxWidth: "400px",
                        marginBottom: "20px",
                      }}
                      src={helpImages["order-tags-new-tag-input"]}
                      alt="step"
                    />
                    <br />
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["order-tags-new-tag-input-color"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      When you are done click the 'Save' button to save the tag.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["order-tags-new-tag-save"]}
                      alt="step"
                    />
                  </li>
                </ol>
              </>
            ),
          },
          {
            name: "Edit Tags",
            subLink: "edit-tags",
            content: (
              <>
                <p>To edit an existing order tag:</p>
                <ol className="procedure reset">
                  <li>
                    <p>Select an order in the orders table.</p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["order-tags-select-order-tags"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Click the 'Tags' button to open a popover containing a
                      list of existing order tags. If there are no existing
                      ones, this list will be empty.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["order-tags-click-tags-dropdown"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Select the 'Manage Tags' button at the top of the popover.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "200px" }}
                      src={helpImages["order-tags-click-manage-tags"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      A dialog will open allowing you to manage (create, edit,
                      or delete) tags. You may see a list of existing tags in
                      this dialog.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["order-tags-manage-tags-dialog"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Click the edit icon button next to the tag you wish to
                      edit in the list of tags.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["order-tags-click-edit-tag"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Enter the new name of the tag in the text input and use
                      the color picker beside the input to select the new color.
                      If you do not wish to change the property of the tag, you
                      may leave it unchanged.
                    </p>
                  </li>
                  <li>
                    <p>
                      When you are done click the 'Save' button to save the tag.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["order-tags-save-edit-tag"]}
                      alt="step"
                    />
                  </li>
                </ol>
              </>
            ),
          },
          {
            name: "Delete Tags",
            subLink: "delete-tags",
            content: (
              <>
                <p>To delete an existing order tag:</p>
                <ol className="procedure reset">
                  <li>
                    <p>Select an order in the orders table.</p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["order-tags-select-order-tags"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Click the 'Tags' button to open a popover containing a
                      list of existing order tags. If there are no existing
                      ones, this list will be empty.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["order-tags-click-tags-dropdown"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Select the 'Manage Tags' button at the top of the popover.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "200px" }}
                      src={helpImages["order-tags-click-manage-tags"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      A dialog will open allowing you to manage (create, edit,
                      or delete) tags. You may see a list of existing tags in
                      this dialog.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["order-tags-manage-tags-dialog"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Click the delete icon button next to the tag you wish to
                      delete.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["order-tags-delete-tag"]}
                      alt="step"
                    />
                    <p>After clicking on the icon, the tag will be deleted.</p>
                  </li>
                </ol>
              </>
            ),
          },
          {
            name: "Add & Remove Tags on Orders",
            subLink: "add-remove-tags-on-orders",
            content: (
              <>
                <p>To add or remove a tag to an order:</p>
                <ol className="procedure reset">
                  <li>
                    <p>
                      Select an order in the orders table that you wish to add
                      or remove a tag on. You can select one or multiple.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "600px" }}
                      src={helpImages["order-tags-select-order-tags"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Click the 'Tags' button to open a popover containing a
                      list of existing order tags. If there are none, you will
                      have to add an order tag.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["order-tags-click-tags-dropdown"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Click to choose the tag you wish to add to the selected
                      order(s). You can click multiple tags consecutively to add
                      them in order.
                    </p>
                    <p>
                      A full checkmark will appear next to the order tags that
                      are applied to all the selected orders. Clicking on an
                      order tag with a full checkmark will remove it from the
                      selected orders.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "150px" }}
                      src={helpImages["order-tags-tag-full-mark"]}
                      alt="step"
                    />
                    <p>
                      A partial checkmark will appear next to the order tags
                      that are applied to only some of the selected orders.
                      Clicking on an order tag with a partial checkmark will add
                      it only to the selected orders that don't already have
                      that tag.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "150px" }}
                      src={helpImages["order-tags-tag-partial-mark"]}
                      alt="step"
                    />
                    <p>
                      If an order tag is not applied to any of the selected
                      orders, no checkmark will be displayed next to it.
                      Clicking on an order tag with no checkmark will add it to
                      the selected orders.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "150px" }}
                      src={helpImages["order-tags-tag-no-mark"]}
                      alt="step"
                    />
                  </li>
                </ol>
              </>
            ),
          },
        ],
      },
    ],
  },
  "edit-order-details": {
    title: "Edit Order Details",
    link: "edit-order-details",
    data: [
      {
        type: "info",
        about: (
          <>
            <p>
              In ShipVista, Order Details are what you see when the Order
              Details Dialog is visible.
            </p>
            <p>
              Some of the details on this dialog are editable even if the order
              has been imported or created. Some of the editable details include
              buyer/recipient address information, shipping-related information,
              and order notes.
            </p>
          </>
        ),
        data: [
          {
            name: "Edit Addresses & Order Information",
            subLink: "edit-addresses",
            content: (
              <>
                <p>
                  You can edit the Buyer and Recipient Addresses for the order
                  within the Order Details dialog.
                </p>
                <ol className="procedure">
                  <li>
                    <p>
                      Open the Order Details dialog by clicking on the
                      Marketplace Order # of an order on the orders table.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{
                        width: "auto",
                        maxWidth: "100%",
                        marginBottom: "20px",
                      }}
                      src={helpImages["edit-order-details-open-order-details"]}
                      alt="step"
                    />
                    <br />
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "100%" }}
                      src={
                        helpImages["edit-order-details-order-details-dialog"]
                      }
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Click the 'Edit' button next to the title text for the
                      Buyer / Recipient Info section of the screen.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "100%" }}
                      src={helpImages["edit-order-details-edit-address-button"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Update the buyer and recipient information details using
                      the input fields beside each label.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "100%" }}
                      src={helpImages["edit-order-details-editing-address"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Click the 'Save' button when you are finished to save the
                      changes.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "100%" }}
                      src={helpImages["edit-order-details-edit-address-save"]}
                      alt="step"
                    />
                  </li>
                </ol>
              </>
            ),
          },
          {
            name: "Add or Edit Order Notes",
            subLink: "edit-notes",
            content: (
              <>
                <p>
                  There are two types of notes that an order can have within
                  ShipVista. Notes could either be internal notes or buyer
                  notes.
                </p>
                <p>To add or edit internal notes:</p>
                <ol className="procedure reset">
                  <li>
                    <p>
                      Click the text next to the word 'Internal' in the Order
                      Notes section. A text area will appear with the existing
                      note if there is a note to edit. If there is no note, the
                      text area will be empty.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "100%" }}
                      src={
                        helpImages[
                          "edit-order-details-start-edit-internal-notes"
                        ]
                      }
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>Enter the contents of the note in this text area.</p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "100%" }}
                      src={
                        helpImages["edit-order-details-editing-internal-notes"]
                      }
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      When you are finished writing the note, click the 'Save'
                      button next to the text area to save the note.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "100%" }}
                      src={
                        helpImages["edit-order-details-end-edit-internal-notes"]
                      }
                      alt="step"
                    />
                  </li>
                </ol>
              </>
            ),
          },
          {
            name: "Order Activity",
            subLink: "order-activity",
            content: (
              <>
                <p>
                  The 'Order Activity' section of the Order Details dialog shows
                  you an activity log of all the actions taken on the order.
                  These activities will include user-initiated actions, such as
                  when the user created the manual order or when the order
                  details were last updated.
                </p>
                <img
                  className={`img-fluid`}
                  style={{ width: "auto", maxWidth: "100%" }}
                  src={helpImages["edit-order-details-order-activity-section"]}
                  alt="step"
                />
              </>
            ),
          },
        ],
      },
    ],
  },
  "order-statuses": {
    title: "Order Statuses",
    link: "order-statuses",
    data: [
      {
        type: "info",
        about: (
          <>
            <p>
              To the left of the orders table on the sidebar is a list of the
              order statuses that your orders are grouped in. When you click the
              Orders tab on the top navigation, you are brought to the Awaiting
              Shipments orders page by default. The orders on this page have
              been paid for and are ready to be shipped. Most of the imported
              orders will appear under this status.
            </p>
          </>
        ),
        data: [
          {
            name: "Status Details",
            subLink: "status-details",
            content: (
              <>
                <p>
                  Orders can be imported into other statuses within ShipVista.
                  The statuses are listed below:
                </p>

                <ul>
                  <li>
                    <h5>Awaiting Payment</h5>
                    <p>
                      Orders that haven't been paid for will be imported into
                      Awaiting Payment status. Not all stores support importing
                      unpaid orders.
                    </p>
                  </li>
                  <li>
                    <h5>On Hold</h5>
                    <p>
                      On Hold orders are orders that were previously awaiting
                      shipment but set aside so that they are not accidentally
                      shipped. Orders that have out-of-stock items or are put on
                      fraud alert can be placed under this status.
                    </p>
                  </li>
                  <li>
                    <h5>Awaiting Shipment</h5>
                    <p>
                      Orders that are waiting to be shipped will be placed under
                      this status. Most of the imported orders will appear here.
                    </p>
                  </li>
                  <li>
                    <h5>Shipped</h5>
                    <p>
                      When an order has had its label created within ShipVista,
                      the order will be moved under this status. Orders that
                      have been marked as shipped will also appear here.
                    </p>
                  </li>
                  <li>
                    <h5>Canceled</h5>
                    <p>
                      Orders that have been canceled within ShipVista will
                      appear under this status. ShipVista will also import any
                      canceled orders under here.
                    </p>
                    <p>
                      When you cancel an order, we do not notify your store, so
                      you may have to repeat this step in your selling channel.
                    </p>
                  </li>
                </ul>

                <p>
                  The status for the orders on your store may not match the
                  status used within ShipVista. ShipVista uses statuses that
                  relate directly to the shipping workflow.
                </p>
              </>
            ),
          },
        ],
      },
    ],
  },
  "edit-services": {
    title: "Edit Services",
    link: "edit-services",
    data: [
      {
        type: "info",
        about: (
          <>
            <p>
              After connecting your carrier account, you are given the option to
              select the available shipping services when you configure shipping
              options for your orders.
            </p>
            <p>
              For most carriers, ShipVista will enable all the available
              services by default. You can choose to enable or disable the
              services that you use or don't use.
            </p>
          </>
        ),
        data: [
          {
            name: "Change Available Services",
            subLink: "change-services",
            content: (
              <>
                <p>
                  To enable or disable specific services for a connected
                  carrier:
                </p>
                <ol className="procedure">
                  <li>
                    <p>
                      Navigate to the Account Settings by clicking the settings
                      icon at the top of the screen.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "100%" }}
                      src={helpImages["edit-services-navigate-settings"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Select 'Integrations' on the sidebar and then click
                      'Carrier Setup'.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "300px" }}
                      src={helpImages["edit-services-navigate-carrier-setup"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      You have two options for the type of shipping account that
                      you want to edit the services: ShipVista Accounts or Your
                      Accounts. Use the tabs at the top of the page to toggle
                      between the list for each account type.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "300px" }}
                      src={helpImages["edit-services-carrier-two"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      If you choose to edit the ShipVista Carrier Account,
                      select the 'Preview' button next to the carrier account to
                      edit.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "300px" }}
                      src={helpImages["edit-services-carrier-preview-button"]}
                      alt="step"
                    />
                    <p>
                      If you choose to edit the Your Carrier Account, select the
                      'Edit' button next to the carrier account to edit.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "300px" }}
                      src={helpImages["edit-services-carrier-edit-button"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      A dialog will open, showing you a list of the shipping
                      services available for that carrier account.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "300px" }}
                      src={helpImages["edit-services-carrier-edit-settings"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Select the service that you want to enable and deselect
                      the ones to disable.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "300px" }}
                      src={helpImages["edit-services-edited-services"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>Click 'Update' to update the carrier settings.</p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "300px" }}
                      src={helpImages["edit-services-update-button-services"]}
                      alt="step"
                    />
                  </li>
                </ol>
              </>
            ),
          },
          {
            name: "Notes on Available Services",
            subLink: "available-services-notes",
            content: (
              <>
                <p>
                  If a service does not appear in the list of services for that
                  carrier, the service is currently not supported.
                </p>

                <p>
                  Disabling a service from the list of available services will
                  remove it from appearing when you get rates throughout
                  ShipVista.
                </p>
              </>
            ),
          },
        ],
      },
    ],
  },
  "getting-rates-with-address": {
    title: "How to Get Rates Using Addresses",
    link: "getting-rates-with-address",
    data: [
      {
        type: "info",
        about:
          "Here you will learn how to use pre-saved addresses saved in the address book or another address to get the rates for a particular shipment.",
        data: [
          {
            name: "Initial steps",
            subLink: "initial-steps",
            content: (
              <>
                <ol className="procedure">
                  <li>
                    <p>
                      Start by logging into the dashboard of the application if
                      you haven't already.
                    </p>
                  </li>
                  <li>
                    <p>
                      Click the Shipments tab on the header of the dashboard to
                      navigate to the shipments part of the application.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["getting-rates-select-shipments-menu"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Ensure that the Create Shipment tab is selected on the
                      left-hand sidebar menu.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["getting-rates-select-create-shipment"]}
                      alt="step"
                      width="280px"
                    />
                  </li>
                </ol>
              </>
            ),
          },
          {
            name: "Enter details",
            subLink: "enter-details",
            content: (
              <>
                <ol className="procedure">
                  <li>
                    <p>
                      A form should appear on the screen prompting you to enter
                      the ship from and ship to addresses to get the rates of
                      the shipments.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["getting-rates-enter-ship-from-address"]}
                      alt="step"
                      width="300px"
                    />
                    <img
                      className={`img-fluid`}
                      src={helpImages["getting-rates-enter-ship-to-address"]}
                      alt="step"
                      width="300px"
                    />
                  </li>
                  <li>
                    <p>
                      After entering the addresses, enter the packaging
                      information in the bottom form. This should include
                      details such as the items and insurance.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["getting-rates-enter-shipping-items"]}
                      alt="step"
                      width="600px"
                    />
                  </li>
                </ol>
              </>
            ),
          },
          {
            name: "Get the available rates",
            subLink: "available-rates",
            content: (
              <>
                <ol className="procedure">
                  <li>
                    <p>
                      Click the 'Get Rates' button to get the available rates
                      for the shipment.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["getting-rates-click-get-rates"]}
                      alt="step"
                      width="300px"
                    />
                  </li>
                  <li>
                    <p>
                      The available rates for that shipment should appear at the
                      bottom of the page.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["getting-rates-rates-appear"]}
                      alt="step"
                      width="300px"
                    />
                  </li>
                </ol>
              </>
            ),
          },
        ],
      },
    ],
  },
  "configure-shipping": {
    title: "Configure Shipping",
    link: "configure-shipping",
    data: [
      {
        type: "info",
        about: (
          <>
            <p>
              To configure shipping for orders within ShipVista, you can use the
              Shipping Information widget and set the details related to
              shipping an order, such as shipping service, dimensions, weight,
              insurance, and other options.
            </p>
            <p>
              You can access the Shipping Information widget in two main ways.
              The first is within the Order Details dialog, and the second is
              through the shipping sidebar.
            </p>
          </>
        ),
        data: [
          {
            name: "Configure Individual Orders",
            subLink: "configure-individual",
            content: (
              <>
                <p>To configure shipping for an individual order:</p>
                <ol className="procedure">
                  <li>
                    <p>
                      Open the Shipping Information widget by opening the Order
                      Details dialog by clicking on a Marketplace Order # on the
                      orders table, or by opening the shipping sidebar by
                      clicking anywhere else on the order.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{
                        width: "auto",
                        maxWidth: "100%",
                        marginBottom: "20px",
                      }}
                      src={helpImages["edit-order-details-open-order-details"]}
                      alt="step"
                    />
                    <br />
                    <img
                      className={`img-fluid`}
                      style={{
                        width: "auto",
                        maxWidth: "100%",
                        marginBottom: "20px",
                      }}
                      src={helpImages["hold-cancel-orders-select-orders-hold"]}
                      alt="step"
                    />
                    <br />
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "200px" }}
                      src={
                        helpImages["configure-shipping-update-shipping-info"]
                      }
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Select the Ship From address - one of the addresses from
                      the address book.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "150px" }}
                      src={
                        helpImages["configure-shipping-set-ship-from-address"]
                      }
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Use the dropdown to select the package type. Either Custom
                      Dimensions or Carrier Provided Parcels.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "150px" }}
                      src={helpImages["configure-shipping-set-package-type"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      If you choose Custom Dimensions, enter the size (length,
                      width, and height) and weight of the package.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "150px" }}
                      src={helpImages["configure-shipping-set-dimensions"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      After you update the shipping information, the details
                      will be saved automatically for you.
                    </p>
                  </li>
                </ol>
              </>
            ),
          },
          {
            name: "Configure Multiple Orders",
            subLink: "configure-multiple",
            content: (
              <>
                <p>
                  You can use Bulk Update to update many orders simultaneously.
                </p>
                <p>To configure shipping for multiple orders in bulk:</p>
                <ol className="procedure reset">
                  <li>
                    <p>
                      Select the orders you want to update in the orders table
                      by clicking on them.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{
                        width: "auto",
                        maxWidth: "100%",
                        marginBottom: "20px",
                      }}
                      src={helpImages["combine-orders-select-orders-combine"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      When you select more than one order, the shipping sidebar
                      will display a widget showing the number of orders
                      currently selected along with a section for Bulk Updates.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "200px" }}
                      src={
                        helpImages[
                          "configure-shipping-select-multiple-orders-sidebar"
                        ]
                      }
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Within the 'Bulk Update' section, use the inputs to fill
                      out the properties you want to update for the selected
                      orders.
                    </p>
                    <p>
                      You can update the Ship From address, the carrier,
                      service, package, weight, and dimensions of all the
                      orders.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "200px" }}
                      src={helpImages["configure-shipping-bulk-update-section"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      When you are finished, click the 'Update Selected Orders'
                      button.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "200px" }}
                      src={
                        helpImages["configure-shipping-update-selected-orders"]
                      }
                      alt="step"
                    />
                  </li>
                </ol>
              </>
            ),
          },
        ],
      },
    ],
  },
  "shipping-presets": {
    title: "Shipping Presets",
    link: "shipping-presets",
    data: [
      {
        type: "info",
        about: (
          <>
            <p>
              Shipping presets are a way to save and easily restore shipping
              configurations so they can be applied when needed.
            </p>
            <p>You can save a preset with the below settings:</p>
            <ul>
              <li>Ship From Address</li>
              <li>Shipping Carrier</li>
              <li>Shipping Service</li>
              <li>Package</li>
              <li>Service Options</li>
              <li>Weight</li>
              <li>Dimensions</li>
              <li>Insurance</li>
              <li>Label Size</li>
            </ul>
          </>
        ),
        data: [
          {
            name: "Create and Manage Presets",
            subLink: "create-preset",
            content: (
              <>
                <p>To create a preset:</p>
                <ol className="procedure">
                  <li>
                    <p>
                      Select any order in the orders table. The sidebar of the
                      table should appear.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{
                        width: "auto",
                        maxWidth: "100%",
                        marginBottom: "20px",
                      }}
                      src={helpImages["hold-cancel-orders-select-orders-hold"]}
                      alt="step"
                    />
                    <br />
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "200px" }}
                      src={
                        helpImages["shipping-presets-orders-sidebar-details"]
                      }
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Click 'Apply Preset' next to the title text Shipping
                      Information. A list of existing presets should appear if
                      there are existing presets.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "200px" }}
                      src={
                        helpImages["shipping-presets-click-apply-preset-single"]
                      }
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Click the 'Manage Presets' button. A dialog allowing you
                      to manage presets will appear.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{
                        width: "auto",
                        maxWidth: "200px",
                        marginBottom: "20px",
                      }}
                      src={helpImages["shipping-presets-existing-presets-list"]}
                      alt="step"
                    />
                    <br />
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "200px" }}
                      src={helpImages["shipping-presets-manage-presets-dialog"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>Click 'New Preset'.</p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "200px" }}
                      src={helpImages["shipping-presets-click-new-preset"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Give the preset a name and fill and add the configurations
                      for the preset by filling in the details.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "200px" }}
                      src={helpImages["shipping-presets-fill-in-preset"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>Click the 'Add Preset' button when finished.</p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "200px" }}
                      src={helpImages["shipping-presets-click-add-preset"]}
                      alt="step"
                    />
                  </li>
                </ol>
              </>
            ),
          },
          {
            name: "Apply a Preset",
            subLink: "apply-preset",
            content: (
              <>
                <p>
                  Once a preset is created, it can be manually applied to a
                  single order or multiple orders.
                </p>
                <h4>Apply to a Single Order</h4>
                <hr />
                <ol className="procedure reset">
                  <li>
                    <p>
                      Select the order you want to apply the preset to by
                      clicking on it in the orders table. The sidebar should
                      open, showing the Shipping Information widget.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{
                        width: "auto",
                        maxWidth: "100%",
                        marginBottom: "20px",
                      }}
                      src={helpImages["hold-cancel-orders-select-orders-hold"]}
                      alt="step"
                    />
                    <br />
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "200px" }}
                      src={
                        helpImages["shipping-presets-orders-sidebar-details"]
                      }
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Click the 'Apply Preset' button next to the Shipping
                      Information title text to open a popover.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "200px" }}
                      src={
                        helpImages["shipping-presets-click-apply-preset-single"]
                      }
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Select the preset that you want to apply from the list of
                      presets.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "200px" }}
                      src={helpImages["shipping-presets-apply-preset-single"]}
                      alt="step"
                    />
                  </li>
                </ol>
                <h4>Apply to Multiple Orders</h4>
                <hr />
                <ol className="procedure reset">
                  <li>
                    <p>
                      Select the orders you want to apply the preset to by
                      clicking on them in the orders table. The sidebar should
                      open, showing the number of orders selected.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{
                        width: "auto",
                        maxWidth: "100%",
                        marginBottom: "20px",
                      }}
                      src={helpImages["combine-orders-select-orders-combine"]}
                      alt="step"
                    />
                    <br />
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "200px" }}
                      src={
                        helpImages[
                          "shipping-presets-orders-sidebar-details-multiple"
                        ]
                      }
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Under the apply preset section on the sidebar, click the
                      dropdown to open a list of existing presets.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "200px" }}
                      src={helpImages["shipping-presets-preset-list"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Select the preset that you want to apply. The Bulk Update
                      section should prefill fields with configurations from the
                      chosen preset. You can still choose to modify some of the
                      shipping details.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "200px" }}
                      src={
                        helpImages["shipping-presets-preset-applied-multiple"]
                      }
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      When you are finished, click the 'Update Selected Orders'
                      button at the bottom to update the orders with the preset.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "200px" }}
                      src={
                        helpImages[
                          "shipping-presets-update-selected-orders-preset"
                        ]
                      }
                      alt="step"
                    />
                  </li>
                </ol>
              </>
            ),
          },
        ],
      },
    ],
  },
  "add-funds": {
    title: "Add Funds",
    link: "add-funds",
    data: [
      {
        type: "info",
        about: (
          <>
            <p>
              When creating a label while using a ShipVista shipping carrier
              account, you must have sufficient funds in your account (SVCash)
              to successfully create the label. This article details the methods
              you can use to add funds to your account.
            </p>
            <p>
              The primary way of adding funds is within the Payments page in the
              account settings. We've also made it easier to add funds from
              anywhere within the app. Both methods are detailed below.
            </p>
          </>
        ),
        data: [
          {
            name: "Add funds through payments page",
            subLink: "payments-page",
            content: (
              <>
                <p>This is the primary way of adding funds to your account:</p>
                <ol className="procedure">
                  <li>
                    <p>
                      Click the settings icon at the top of the page to go to
                      the account settings.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["address-book-select-settings"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      On the left-hand menu, click to expand the account panel.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "200px" }}
                      src={helpImages["add-funds-expand-account-panel"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Once the panel opens up, click the 'Payments' button to
                      open the payments page.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "200px" }}
                      src={helpImages["add-funds-click-payments"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      This page will list all your payment details such as
                      credit card information and SVCash balance.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "100%" }}
                      src={helpImages["add-funds-payments-page"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Click the 'Add Funds' button to the right of the page.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "300px" }}
                      src={helpImages["add-funds-click-add-funds-button"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Enter the number of dollars to add to your account and
                      select the card to deduct the funds.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{
                        width: "auto",
                        maxWidth: "500px",
                        marginBottom: "20px",
                      }}
                      src={helpImages["add-funds-add-funds-dialog"]}
                      alt="step"
                    />
                    <br />
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["add-funds-add-funds-dialog-filled"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Click the 'Submit' button to add the funds to your
                      account. If the action is successful, funds should be
                      added. You can visit the Transaction History page of the
                      account settings to see this transaction.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "200px" }}
                      src={helpImages["add-funds-add-funds-submit"]}
                      alt="step"
                    />
                  </li>
                </ol>
              </>
            ),
          },
          {
            name: "Add funds from any page",
            subLink: "any-page",
            content: (
              <>
                <p>
                  If you need to add funds without leaving the current page that
                  you are on:
                </p>
                <ol className="procedure reset">
                  <li>
                    <p>
                      Open the Username dropdown widget by clicking on your
                      username on the right part of the top navigation.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["add-funds-click-username-dropdown"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Click the 'Add Fund' button located within this dropdown.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["add-funds-click-add-funds-dropdown"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Follow the remaining steps starting from step # 6 in the
                      top section of this article to add funds.
                    </p>
                  </li>
                </ol>
              </>
            ),
          },
        ],
      },
    ],
  },
  "add-card": {
    title: "Add Cards",
    link: "add-cards",
    data: [
      {
        type: "info",
        about: (
          <>
            <p>
              To add funds to your account, you first have to register a card
              within ShipVista.
            </p>
            <p>
              The primary way of adding a card is within the Payments page,
              which can be accessed in the account settings. We've also made it
              easier to add a card anywhere from within the app. Both methods
              are detailed below.
            </p>
          </>
        ),
        data: [
          {
            name: "Add a card through payments page",
            subLink: "payments-page",
            content: (
              <>
                <p>This is the primary way of adding a card to your account:</p>
                <ol className="procedure">
                  <li>
                    <p>
                      Click the settings icon at the top of the page to go to
                      the account settings.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["address-book-select-settings"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      On the left-hand menu, click to expand the account panel.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "200px" }}
                      src={helpImages["add-funds-expand-account-panel"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Once the panel opens, click 'Payments' to open the
                      payments page.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "200px" }}
                      src={helpImages["add-funds-click-payments"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      This page will list all your payment details, such as
                      credit card information and SVCash balance.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "100%" }}
                      src={helpImages["add-funds-payments-page"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Click the 'Add Card' button to the left of the page. A
                      dialog should appear which will allow you to enter your
                      card details.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["add-card-click-add-card-payments"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Fill in the form within the dialog with the details of the
                      card that you want to add.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "300px" }}
                      src={helpImages["add-card-add-card-dialog"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Click the 'Save' button at the bottom of the dialog to
                      save the card to your account.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["add-card-click-save-card"]}
                      alt="step"
                    />
                  </li>
                </ol>
              </>
            ),
          },
          {
            name: "Add a card on any page",
            subLink: "any-page",
            content: (
              <>
                <p>
                  If you need to add a card without leaving the current page
                  that you are on:
                </p>
                <ol className="procedure reset">
                  <li>
                    <p>
                      Open the Username dropdown widget by clicking on your
                      username on the right part of the top navigation.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["add-funds-click-username-dropdown"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Click the 'Add Fund' button located within this dropdown.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["add-funds-click-add-funds-dropdown"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>Click the 'Add Card' button on the dialog that opens.</p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["add-card-click-add-card-dialog"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Follow the remaining steps starting from step # 6 in the
                      top section of this article to add a card.
                    </p>
                  </li>
                </ol>
              </>
            ),
          },
        ],
      },
    ],
  },
  "track-shipment": {
    title: "Track Shipment",
    link: "track-shipment",
    data: [
      {
        type: "info",
        about: (
          <>
            <p>
              After a label has been created for the shipment, the shipment can
              be tracked using a tracking number. ShipVista allows you to track
              that shipment using the tracking number. Currently, the main way
              of tracking a shipment is by using tracking links for the shipment
              on the Shipment History page.
            </p>
          </>
        ),
        data: [
          {
            name: "Tracking Links",
            subLink: "tracking-links",
            content: (
              <>
                <p>
                  When you visit the Shipment Histories page, each shipment will
                  have a tracking link you can use to visit the tracking page of
                  the shipping carrier, where you can see all the events for the
                  shipment.
                </p>
                <ol className="procedure">
                  <li>
                    <p>
                      Navigate to the Shipment pages by clicking on 'Shipments'
                      in the top navigation.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["track-shipment-navigate-shipments"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      On the left-hand sidebar, click 'Shipment History' to view
                      the history of shipments created throughout the
                      application.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["track-shipment-click-shipment-history"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      The page shows all the shipments created listed in reverse
                      chronological order starting with the most recent shipment
                      first
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["track-shipment-shipment-history-table"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Click the tracking number beside a shipment in the table
                      to be directed to the carrier's tracking page for that
                      shipment.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["track-shipment-click-tracking-number"]}
                      alt="step"
                    />
                  </li>
                </ol>
              </>
            ),
          },
        ],
      },
    ],
  },
  "settings-overview": {
    title: "Settings Overview",
    link: "settings-overview",
    data: [
      {
        type: "info",
        about: (
          <>
            <p>
              The settings pages within ShipVista allow you to modify and edit
              any settings related to your account that apply throughout the
              entire application. The pages within the settings are placed into
              categories that allow you to better understand their functionality
              as well as identify related settings. The categories can be seen
              on the sidebar of the settings part of the application and are
              listed below:
            </p>
            <ul>
              <li>
                <Link href={routes.help}>Account</Link>:
                <p>
                  User related-settings. Includes My Profile, Payments, Offers
                  Available, Transaction History, Subscription, and Air Miles
                  Rewards.
                </p>
              </li>
              <li>
                <Link href={routes.help}>Integrations</Link>:
                <p>
                  Settings related to integrations with different carriers,
                  marketplaces, or stores. Includes Store Setup and Carrier
                  Setup.
                </p>
              </li>
              <li>
                <Link href={routes.help}>Shipping</Link>:
                <p>
                  Settings that are used to aid in the shipping process.
                  Includes Address Book and Box Library.
                </p>
              </li>
              <li>
                <Link href={routes.help}>Feed</Link>:
                <p>
                  Settings that allow you to manage feeds and their statuses.
                  Includes Bulk Orders Feed and Bulk Affiliate User Feed.
                </p>
              </li>
            </ul>
          </>
        ),
        data: [
          {
            name: "Accessing Settings Using Sidebar",
            subLink: "sidebar",
            content: (
              <>
                <p>
                  You can access the setting pages by using the sidebar after
                  navigating to the settings in the top navigation
                </p>
                <ol className="procedure">
                  <li>
                    <p>
                      Navigate to the account settings by clicking on the
                      settings icon in the top navigation.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["edit-services-navigate-settings"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      On the left, you will see the settings sidebar, which will
                      list all the settings categories mentioned above with the
                      individual settings pages under each category. Each
                      category will be a panel on the sidebar, and by default,
                      the account panel will be expanded, with the current page
                      set to My Profile.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["setting-overview-settings-sidebar"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Expand the panel for a specific category to see the pages
                      under that category. In this example, we will expand the
                      'Integrations' panel to see the pages under the
                      Integrations Category.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={
                        helpImages["setting-overview-settings-sidebar-expanded"]
                      }
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      You will see the pages under the chosen category. For this
                      example, we can see the Store Setup and Carrier Setup
                      settings pages. Click on a page to set it as the active
                      page.
                    </p>
                  </li>
                  <li>
                    <p>
                      After a settings page has been set as the active page, it
                      will be highlighted white on the settings sidebar.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={
                        helpImages[
                          "setting-overview-settings-sidebar-page-active"
                        ]
                      }
                      alt="step"
                    />
                  </li>
                </ol>
              </>
            ),
          },
          {
            name: "Accessing Settings Using the Username Dropdown",
            subLink: "username-dropdown",
            content: (
              <>
                <p>
                  You can access each of the setting pages by using the Username
                  dropdown navigation widget in the top right corner of the
                  application.
                </p>
                <ol className="procedure reset">
                  <li>
                    <p>
                      While inside the application, click your username to open
                      the Username dropdown widget.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["add-funds-click-username-dropdown"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      This widget will allow you to quickly access the most
                      important user information directly from any page, such as
                      your account number and current balance, as well as each
                      of the setting pages.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={
                        helpImages[
                          "setting-overview-username-dropdown-displayed"
                        ]
                      }
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Each of the tabs in the lower portion of the widget
                      corresponds to each category of the user settings.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={
                        helpImages["setting-overview-username-dropdown-tabs"]
                      }
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Clicking on the tab for a specific category will change
                      the bottom panel to display pages under that category. By
                      default, the tab for the Account category will be active,
                      meaning the account-related setting pages will be shown.
                      For this example, we will select the 'Integrations' tab to
                      show all the setting pages under integrations.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={
                        helpImages["setting-overview-click-integrations-tab"]
                      }
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Select the settings page you want to visit by clicking on
                      the button within the active tab panel that matches. For
                      this example, we will visit the Carrier Setup page. When
                      the button is clicked, the page for carrier setup settings
                      will be displayed immediately.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={
                        helpImages[
                          "setting-overview-username-dropdown-carriers"
                        ]
                      }
                      alt="step"
                    />
                  </li>
                </ol>
              </>
            ),
          },
        ],
      },
    ],
  },
  "my-profile": {
    title: "My Profile",
    link: "my-profile",
    data: [
      {
        type: "info",
        about: (
          <>
            <p>
              The My Profile page displays the profile information of your user
              account. This is where you can change or update personal
              information such as contact details, address, and the account
              password.
            </p>
          </>
        ),
        data: [
          {
            name: "Updating Contact Details and address",
            subLink: "basic-contact-and-address",
            content: (
              <>
                <p>
                  You can update your Basic Contact Details as well as Address
                  Details by following the steps below:
                </p>
                <ol className="procedure">
                  <li>
                    <p>Navigate to the My Profile settings page.</p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "100%" }}
                      src={helpImages["my-profile-my-profile-page"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      On the left, you can edit the contact details such as your
                      name, number, company name, email, and fax number. Use the
                      inputs and labels to update the details to their correct
                      values. You can leave the form input at its default if you
                      do not need to change that specific detail.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["my-profile-my-profile-contact"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      On the right, you can edit the address details. This
                      address is the address for your company or business. You
                      can use the topmost input to search for the location using
                      Google's Place Autocomplete. If you prefer, you could also
                      click the checkbox 'Enter by Your Own' and fill in the
                      details of the address manually.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["my-profile-my-profile-address"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      When you are finished, click the 'Update' button at the
                      bottom of the page to update your profile information.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["my-profile-my-profile-update"]}
                      alt="step"
                    />
                  </li>
                </ol>
              </>
            ),
          },
          {
            name: "Changing Password",
            subLink: "changing-password",
            content: (
              <>
                <p>To change your password when you are logged in:</p>
                <ol className="procedure reset">
                  <li>
                    <p>Navigate to the My Profile settings page.</p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "100%" }}
                      src={helpImages["my-profile-my-profile-page"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>Click the 'Change Password' button.</p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["my-profile-click-change-password"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      A dialog should open and display fields to enter your old
                      password and new password.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["my-profile-change-password-dialog"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Enter the old and new passwords, and re-type the new
                      password in the fields that are labeled.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={
                        helpImages["my-profile-change-password-dialog-filled"]
                      }
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      After you are finished, click the 'Update Password'
                      button.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["my-profile-change-password-update"]}
                      alt="step"
                    />
                  </li>
                </ol>
              </>
            ),
          },
          {
            name: "Forgot Password",
            subLink: "forgot-password",
            content: (
              <>
                <p>
                  If you forgot your password, reset it by logging out and
                  following the below steps:
                </p>
                <ol className="procedure reset">
                  <li>
                    <p>Click the login button to open the login dialog.</p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["my-profile-click-login-button"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>Click the 'Forgot Password?' link.</p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["my-profile-click-forgot-password"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Enter the email associated with your account and click the
                      'Send Instructions' button. Instructions to reset your
                      password will be sent to your email.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["my-profile-forgot-password-filled"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Continue following the instructions in the email to reset
                      your account password and get access to your account.
                    </p>
                  </li>
                </ol>
              </>
            ),
          },
        ],
      },
    ],
  },
  payments: {
    title: "Payments",
    link: "payments",
    data: [
      {
        type: "info",
        about: (
          <>
            <p>
              The Payments page displays important information related to
              payment processing throughout the app.
            </p>
          </>
        ),
        data: [
          {
            name: "Adding Funds",
            subLink: "adding-funds",
            content: (
              <>
                <p>
                  Whenever you create a label within ShipVista using a ShipVista
                  Carrier Account, you will need sufficient funds (SVCash) in
                  your account.
                </p>
                <p>
                  You will need to add a card to your account to add funds.
                  Multiple cards can be added to your account.
                </p>
                <p>
                  To add funds to your account, follow the instructions on the
                  Add Funds help page.
                </p>
              </>
            ),
          },
          {
            name: "Adding a Card",
            subLink: "adding-card",
            content: (
              <>
                <p>
                  To add a card to your account, follow the instructions on the
                  Add Card help page.
                </p>
              </>
            ),
          },
        ],
      },
    ],
  },
  "offers-available": {
    title: "Offers Available",
    link: "offers-available",
    data: [
      {
        type: "info",
        about: (
          <>
            <p>
              Any bonuses that you gain from promotional offers such as the open
              account offer described on{" "}
              <Link href={routes.openAccountOffer}>this</Link> page will appear
              here. You can use this page to determine the total amount of
              available cash you have gained and the amount claimed.
            </p>
          </>
        ),
        data: [
          {
            name: "Gaining bonuses for Open Account Offer",
            subLink: "gain-bonus-open-account",
            content: (
              <>
                <p>
                  As mentioned above, the terms for obtaining bonuses through
                  opening an account can be found on{" "}
                  <Link href={routes.openAccountOffer}>this</Link> page.
                </p>
              </>
            ),
          },
          {
            name: "Claiming bonuses for Open Account Offer",
            subLink: "claim-bonus-open-account",
            content: (
              <>
                <p>
                  To claim your bonus for opening an account, navigate to the
                  Open Account Offer page and click the 'Claim' button on the
                  Open Account Offer card. This action can only be performed
                  once.
                </p>
              </>
            ),
          },
        ],
      },
    ],
  },
  "transaction-history": {
    title: "Transaction History",
    link: "transaction-history",
    data: [
      {
        type: "info",
        about: (
          <>
            <p>
              The transaction history page shows you all the transactions that
              were made on your accounts such as label payments or deposits. The
              status indicating whether the transaction was successful or not
              can also be found on this page.
            </p>
          </>
        ),
        data: [
          {
            name: "Viewing Transaction",
            subLink: "viewing-transactions",
            content: (
              <>
                <p>To view the transactions on your account:</p>
                <ol className="procedure">
                  <li>
                    <p>Navigate to the Transaction History settings page.</p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={
                        helpImages[
                          "transaction-history-transaction-history-page"
                        ]
                      }
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      You will see a table filled with all the transactions for
                      the account. The transactions will be displayed in reverse
                      chronological order.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["transaction-history-transaction-table"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      You can search for a specific transaction by using the
                      search input. For example, here we are searching by the
                      transaction number.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["transaction-history-transaction-search"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      You can also apply a filter to the transactions to view
                      the transactions that match the criteria. Here we are
                      filtering by the type of the transaction to show only
                      deposits.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["transaction-history-transaction-filter"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      You can clear a filter or search by clicking the 'Clear
                      the FIlters' button to the right that appears when a
                      filter or search is applied.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={
                        helpImages[
                          "transaction-history-transaction-clear-filter"
                        ]
                      }
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      You can see your current SVCash balance after all the
                      transactions at the top right corner.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={
                        helpImages["transaction-history-transaction-balance"]
                      }
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      You can also change the table page size or page number by
                      using the controls at the bottom.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "100%" }}
                      src={
                        helpImages[
                          "transaction-history-transaction-page-controls"
                        ]
                      }
                      alt="step"
                    />
                  </li>
                </ol>
              </>
            ),
          },
        ],
      },
    ],
  },
  subscriptions: {
    title: "Subscriptions",
    link: "subscriptions",
    data: [
      {
        type: "info",
        about: (
          <>
            <p>
              Any settings related to your current subscription plan details as
              well as any other plans and changes between plans can be found on
              this page. You can also use this page to manage your billing
              information.
            </p>
          </>
        ),
        data: [
          {
            name: "Viewing Current Plan",
            subLink: "viewing-plan",
            content: (
              <>
                <p>To view the details of your current plan:</p>
                <ol className="procedure">
                  <li>
                    <p>Navigate to the Subscription settings page.</p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "100%" }}
                      src={helpImages["subscription-subscription-page"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      You will see two sections on the page: Your Plan and
                      Billing Information. In the section titled Your Plan, you
                      will see all the relevant details about your current
                      subscription plan as well as details on your future plan
                      if you are changing it.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["subscription-subscription-your-plan"]}
                      alt="step"
                    />
                    <p>
                      ShipVista currently has four different subscription plans
                      with their own individual features. The plans can be found
                      on <Link href={routes.pricing}>this</Link> page and every
                      user is registered under a plan. When you sign up, you are
                      placed on the free Starter Plan which has a few
                      restrictions when compared to paid plans.
                    </p>
                  </li>
                  <li>
                    <p>
                      To see a list of the current feature and any restrictions
                      on your current plan, click the 'See All Features' button.
                    </p>
                  </li>
                  <li>
                    <p>
                      If you have been billed for a plan, you can view your past
                      invoices by clicking the 'View past invoice' button.
                    </p>
                  </li>
                  <li>
                    <p>
                      You can also view and compare your current plan with other
                      plans by clicking the 'Change Plan' button.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["subscription-change-plan-button"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      When you click 'Change Plan', a new dialog should open,
                      listing all the available plans and their details. Within
                      this dialog, you can find all the relevant information you
                      need to compare and change your plan.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["subscription-change-plan-dialog"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      At the bottom of each plan, there will be a button to see
                      all the features of the plan.
                    </p>
                  </li>

                  <li>
                    <p>
                      There will also be a button on every plan to upgrade or
                      downgrade your plan, depending on the level of restriction
                      that the new plan comes with.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["subscription-change-plan-ends"]}
                      alt="step"
                    />
                    <p>
                      If you are either on the Starter or Beta plan, you can
                      immediately change your plan to the other without any
                      charge to your account. If you are on any other plan and
                      you want to downgrade your plan to Starter or Beta, you
                      will be prompted to give your reason for wanting to
                      downgrade and the plan change will not happen until the
                      end of your billing period. If you are on any plan that is
                      paid and you want to upgrade to another paid plan, the
                      change will happen immediately. If the change is a
                      downgrade, it will happen at the end of your billing
                      period.
                    </p>
                    <p>
                      If you want to change to a free plan, you can use the
                      button at the top of the dialog labeled 'Change to Free
                      Plan'.
                    </p>
                  </li>
                </ol>
              </>
            ),
          },
          {
            name: "Changing Current Plan",
            subLink: "changing-plan",
            content: (
              <>
                <p>To change your current plan:</p>
                <ol className="procedure reset">
                  <li>
                    <p>Navigate to the Subscription settings page.</p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "100%" }}
                      src={helpImages["subscription-subscription-page"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      You will see two sections on the page: Your Plan and
                      Billing Information. In the section titled Your Plan, you
                      will see all the relevant details about your current
                      subscription plan as well as details on your future plan
                      if you are changing it.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["subscription-subscription-your-plan"]}
                      alt="step"
                    />
                    <p>
                      ShipVista currently has four different subscription plans
                      with their own individual features. The plans can be found
                      on <Link href={routes.pricing}>this</Link> page and every
                      user is registered under a plan. When you sign up, you are
                      placed on the free Starter Plan which has a few
                      restrictions when compared to paid plans.
                    </p>
                  </li>
                  <li>
                    <p>Click the 'Change Plan' button.</p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["subscription-change-plan-button"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      When you click 'Change Plan', a new dialog should open,
                      listing all the available plans and their details. Within
                      this dialog, you can find all the relevant information you
                      need to compare and change your plan.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["subscription-change-plan-dialog"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      At the bottom of each plan will be a button to upgrade or
                      downgrade your plan, depending on the level of restriction
                      that the new plan comes with.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["subscription-change-plan-ends"]}
                      alt="step"
                    />
                    <p>
                      If you are either on the Starter or Beta plan, you can
                      immediately change your plan to the other without any
                      charge to your account. If you are on any other plan and
                      you want to downgrade your plan to Starter or Beta, you
                      will be prompted to give your reason for wanting to
                      downgrade, and the plan change will not happen until the
                      end of your billing cycle. If you are on any plan that is
                      paid and you want to upgrade to another paid plan, the
                      change will happen immediately. If the change is a
                      downgrade, it will happen at the end of your billing
                      period.
                    </p>
                    <p>
                      If you want to change to a free plan, you can use the
                      button at the top of the dialog labeled 'Change to Free
                      Plan'.
                    </p>
                  </li>
                  <li>
                    <p>
                      Click the 'Upgrade' or 'Downgrade' button for the plan
                      that you wish to change to.
                    </p>
                  </li>
                  <li>
                    <p>
                      If you are changing to a paid plan, another dialog should
                      open with information on the plan change.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["subscription-upgrade-plan-dialog"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      There will be a message near the top of the dialog
                      indicating whether the change will be processed
                      immediately or at a later time depending on if you are on
                      a current paid plan.
                    </p>
                  </li>
                  <li>
                    <p>
                      You will have the option to select if you are choosing the
                      yearly or monthly version of the plan that you want and
                      the price.
                    </p>

                    <p>
                      Note: Regardless of whether your plan is monthly or
                      yearly, you will be billed monthly. If your plan is
                      yearly, then you will be charged the yearly amount divided
                      by twelve each month.
                    </p>
                  </li>
                  <li>
                    <p>
                      The date that the plan will end will be shown for you as
                      well as the total.
                    </p>
                  </li>
                  <li>
                    <p>
                      If you have a promo code to apply when changing plans, you
                      can click the 'Have a Promo Code?' button near the bottom.
                    </p>
                  </li>
                  <li>
                    <p>
                      After clicking the button, an input will appear allowing
                      you to enter the promo code. Enter the code that you were
                      given exactly as it is case-sensitive.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["subscription-promo-filled"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      After you finish entering the promo code, click the
                      'Apply' button to apply the code.
                    </p>
                  </li>
                  <li>
                    <p>
                      If the promo code is valid, the code will be applied and
                      the price will be updated.
                    </p>
                  </li>
                  <li>
                    <p>
                      Next, you have to select the credit card to use for the
                      billing of the plan by clicking on the dropdown at the
                      bottom.
                    </p>
                  </li>
                  <li>
                    <p>
                      If you have not added a card yet to your account, you can
                      click the option to add a new card and follow the steps to
                      add a card on the Add Card help page.
                    </p>
                  </li>
                  <li>
                    <p>
                      When you are satisfied with the detail of your plan
                      change, click the 'Change Plan' button.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["subscription-click-change-plan"]}
                      alt="step"
                    />
                  </li>
                </ol>
              </>
            ),
          },
          {
            name: "Viewing Current Billing Information",
            subLink: "viewing-billing-information",
            content: (
              <>
                <p>To view your current subscription billing information:</p>
                <ol className="procedure reset">
                  <li>
                    <p>Navigate to the Subscription settings page.</p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "100%" }}
                      src={helpImages["subscription-subscription-page"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      You will see two sections on the page: Your Plan and
                      Billing Information. In the section titled Billing
                      Information, you will see the details of your current
                      billing information.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={
                        helpImages[
                          "subscription-subscription-billing-information"
                        ]
                      }
                      alt="step"
                    />
                    <p>
                      The card displayed on the billing information is charged
                      every billing period if your current subscription plan is
                      a paid plan.
                    </p>
                  </li>
                  <li>
                    <p>
                      On the left side of the Billing Information is the Billing
                      Address. This address is the same address that is
                      registered on the card.
                    </p>
                  </li>
                  <li>
                    <p>
                      To the right side of the Billing Information is the
                      Billing Method. This contains the details of the card that
                      is charged.
                    </p>
                  </li>
                </ol>
              </>
            ),
          },
          {
            name: "Changing Current Billing Information",
            subLink: "viewing-billing-information",
            content: (
              <>
                <p>To change your current subscription billing information:</p>
                <ol className="procedure reset">
                  <li>
                    <p>Navigate to the Subscription settings page.</p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto", maxWidth: "100%" }}
                      src={helpImages["subscription-subscription-page"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      You will see two sections on the page: Your Plan and
                      Billing Information. In the section titled Billing
                      Information, you will see the details of your current
                      billing information.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={
                        helpImages[
                          "subscription-subscription-billing-information"
                        ]
                      }
                      alt="step"
                    />
                    <p>
                      The card displayed on the billing information is charged
                      every billing period if your current subscription plan is
                      a paid plan.
                    </p>
                  </li>
                  <li>
                    <p>
                      On the bottom portion of the billing information, click
                      the 'Change Billing Information' button.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["subscription-click-change-billing"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      A dialog will appear, telling you to select a card as the
                      payment method for the subscription.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["subscription-change-billing-dialog"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      If you haven't added a card yet to your account, you can
                      click the option to add a new card and follow the steps to
                      add a card on the Add Card help page.
                    </p>
                  </li>
                  <li>
                    <p>
                      Once you have selected a card, click the 'Change' button
                      to change the billing method. The billing address will be
                      updated to the address of the card.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["subscription-click-change-for-billing"]}
                      alt="step"
                    />
                  </li>
                </ol>
              </>
            ),
          },
        ],
      },
    ],
  },
  "batch-shipping": {
    title: "Batch Shipment",
    link: "batch-shipment",
    data: [
      {
        type: "info",
        about: (
          <>
            <p>
              Within ShipVista, you can group multiple orders and batch create
              labels for them in the background while you perform other tasks.
              This feature is helpful when you need to organize label creation
              for multiple orders and ship them all at once.
            </p>
            <p>
              When you create a batch, every label created will count as a
              shipment except for multi-package shipments which will count as a
              single shipment.
            </p>
          </>
        ),
        data: [
          {
            name: "Create Labels within a Batch",
            subLink: "create-labels",
            content: (
              <>
                <p>To batch create labels:</p>
                <ol className="procedure">
                  <li>
                    <p>
                      Navigate to either the Awaiting Shipment or On Hold Orders
                      page. You will see the orders table on the page.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{
                        width: "auto",
                        maxWidth: "400px",
                        marginBottom: "20px",
                      }}
                      src={helpImages["click-orders-tab"]}
                      alt="step"
                    />
                    <br />
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["batch-navigate-awaiting-shipments"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Select at least two orders in the orders table. The orders
                      sidebar should open, showing the number of orders
                      selected.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{
                        width: "auto",
                        maxWidth: "400px",
                        marginBottom: "20px",
                      }}
                      src={helpImages["combine-orders-select-orders-combine"]}
                      alt="step"
                    />
                    <br />
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={
                        helpImages[
                          "shipping-presets-orders-sidebar-details-multiple"
                        ]
                      }
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      On the orders sidebar, you will see various form widgets
                      used to update the shipping details for the selected
                      orders. There will be a widget for applying a preset to
                      the selected orders. There will also be a bulk update
                      widget to configure the shipping details.
                    </p>

                    <p>
                      At the top of the sidebar, you can batch create labels for
                      the selected orders by clicking the 'Create Label' button.
                      The saved shipping configuration such as the shipping
                      carrier, ship from address, and service for the orders
                      will be used when creating the labels. These saved details
                      could be different for each of the selected orders.
                    </p>

                    <p>
                      If there are errors while getting rates for any selected
                      order, an error message will be displayed under the Create
                      Label button. If there are no errors and you want to go
                      straight to creating the label with the saved shipping
                      configurations, click the Create Label button and skip to
                      step # 12.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["batch-create-label-shipping-errors"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      If there are any errors while getting rates, click the
                      error message below the Create Label button. A dialog will
                      open showing you the errors that are present and the order
                      that generated the error.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["batch-error-order-summary-dialog"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Usually, the error generated will be caused by missing or
                      incorrect data in the orders that were selected. You can
                      click the Order Id for the order to open the Order Details
                      dialog and update the order with any missing information.
                      To learn about the Order Details, visit the Edit Order
                      Details help page.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["batch-click-order-id-details"]}
                      alt="step"
                    />
                    <p>
                      The error might also be caused by missing details in the
                      shipping configuration for the orders. You can visit the
                      Configure Shipping help page to learn about configuring
                      shipping. If the error is caused by missing information in
                      the shipping configuration such as a missing Ship From
                      address, and no rates available for the carrier service,
                      you will have to update the shipping configuration using
                      the bulk update widget at the bottom of the orders
                      sidebar.
                    </p>
                  </li>
                  <li>
                    <p>
                      Once you have fixed all the errors involving missing or
                      incorrect details in the orders, you can close the error
                      dialog and follow the below steps to update the shipping
                      configuration.
                    </p>
                  </li>
                  <li>
                    <p>
                      (optional) You can use the apply preset dropdown to select
                      a preset, the form in the bulk update widget will update
                      with the saved configuration details from the preset. To
                      learn more about presets, visit the Shipping Presets help
                      page.
                    </p>

                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["shipping-presets-preset-list"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      In the bulk update widget, use the form inputs to make
                      changes to the saved shipping configuration of the orders.
                      This will include the Ship From address, the carrier,
                      services, and dimensions.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["batch-bulk-update-orders"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      When you are finished setting the configurations, click
                      the 'Update Selected Orders' button to update the orders.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={
                        helpImages["batch-click-update-selected-orders-filled"]
                      }
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      If the orders are successfully updated, ShipVista will get
                      the rates for the selected orders and revalidate the
                      orders to see if the labels could be created. If there are
                      any errors in getting the rates, an error message will be
                      present under the Create Label button.
                    </p>
                  </li>
                  <li>
                    <p>
                      If there are no errors present while getting rates for the
                      orders, there will be no error messages. You can click the
                      Create Label button to start the batch label creation.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["batch-create-label-no-errors"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      A dialog will appear showing you the total cost to create
                      the batch and the service that will be used.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["batch-create-batch-label-dialog"]}
                      alt="step"
                    />
                    <p>
                      You can use the date input to select the ship date for the
                      shipments within the batch.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["batch-batch-dialog-input"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      If you are satisfied with the batch details, you can click
                      'Create Label' to start the batch label creation.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["batch-batch-dialog-button"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      A dialog should open showing you the status of the
                      shipments within the batch and the number of shipments
                      that were successful and the number that failed.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["batch-batch-dialog-processing"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      If the process is taking too long, you can close the
                      dialog and continue doing other tasks within the
                      application. You can visit the Shipment History page to
                      view the status of the shipments within the batch. Visit
                      the Track Shipment help page to learn about Shipment
                      History and tracking shipments.
                    </p>
                  </li>
                </ol>
              </>
            ),
          },
        ],
      },
    ],
  },
  "mark-as-shipped": {
    title: "Mark Orders as Shipped",
    link: "mark-shipped",
    data: [
      {
        type: "info",
        about: (
          <>
            <p>
              The mark as shipped option in ShipVista allows you to manually
              move orders or shipments to the shipped status without creating a
              label. This action can not be reversed and it can only be done on
              orders that are either awaiting shipment, on hold, or canceled.
            </p>
          </>
        ),
        data: [
          {
            name: "Mark as Shipped From Orders Table",
            subLink: "orders-table",
            content: (
              <>
                <ol className="procedure">
                  <li>
                    <p>
                      Navigate to either the Awaiting Shipment, On Hold, or
                      Canceled Orders page. You will see the orders table on the
                      page.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{
                        width: "auto",
                        maxWidth: "400px",
                        marginBottom: "20px",
                      }}
                      src={helpImages["click-orders-tab"]}
                      alt="step"
                    />
                    <br />
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["batch-navigate-awaiting-shipments"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Select the orders that you would like to mark as shipped
                      in the orders table. You can select one or many.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["combine-orders-select-orders-combine"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Click the 'Actions' button to open a popover and select
                      the 'Mark as Shipped' option from the list of actions.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["mark-shipped-click-mark-as-shipped"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      A dialog will open showing a list of the selected orders.
                      Besides each order, there will be inputs to enter the
                      tracking number and the carrier for the shipment. Fill in
                      the appropriate information for each order in the list.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["mark-shipped-mark-as-shipped-dialog"]}
                      alt="step"
                    />
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["mark-shipped-mark-as-shipped-filled"]}
                      alt="step"
                    />
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["mark-shipped-mark-as-shipped-date"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      When you are finished, click the 'Mark As Shipped' button.
                      This will move the orders to the fulfillment page and
                      treat them as a shipment without creating a label.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["mark-shipped-mark-as-shipped-button"]}
                      alt="step"
                    />
                  </li>
                </ol>
              </>
            ),
          },
        ],
      },
    ],
  },
  "end-of-day": {
    title: "End Of Day Forms",
    link: "end-of-day",
    data: [
      {
        type: "info",
        about: (
          <>
            <p>
              When creating shipments, some carriers will require a shipping
              manifest listing all the shipments for the day. Note that the name
              of this document will differ based on the carrier such as Order
              Summary or End Of Day Form. This document is referred to as an
              'End of Day Form' within ShipVista because it is usually
              transmitted at the end of the day.
            </p>
            <p>
              With ShipVista, you can close the shipments for certain carriers
              and print the form to be scanned by the carrier driver.
            </p>
          </>
        ),
        data: [
          {
            name: "End of Day",
            subLink: "process",
            content: (
              <>
                <p>
                  The process for closing shipments for the End of Day forms is
                  listed as follows:
                </p>
                <h4>Viewing the open shipments</h4>
                <hr />
                <ol className="procedure">
                  <li>
                    <p>
                      Navigate to the Shipments tab located on the top
                      navigation.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["end-day-navigate-to-shipments"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Click the 'End of Day' panel on the left-hand sidebar to
                      go to the End of Day page.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["end-day-click-end-day-panel"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      When you visit the End of Day page, ShipVista will display
                      the open shipments for the current day.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["end-day-open-shipments-table"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      The open shipments will display in a table grouped by the
                      ship date and carrier. The table allows you to see the
                      number of shipments that are still open for a certain
                      carrier on a specific day.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["end-day-open-shipments-table-column"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      You can use the date picker to select a single date or a
                      date range to view shipments with the respective ship
                      date.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["end-day-open-shipments-date"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Click the 'Close Shipments' button on each record to close
                      the shipments for that carrier and day.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["end-day-click-close-shipments"]}
                      alt="step"
                    />
                  </li>
                </ol>
                <h4>Closing and Printing</h4>
                <p>
                  After clicking the 'Close Shipments' button for a record in
                  the Open Shipments table, the Close Shipments dialog will
                  appear listing all the open shipments for the carrier on the
                  chosen ship date. There will be another table in this dialog
                  showing the details of the shipments that will be closed.
                </p>
                <img
                  className={`img-fluid`}
                  style={{ width: "auto" }}
                  src={helpImages["end-day-close-shipments-dialog"]}
                  alt="step"
                />
                <hr />
                <p>To close shipments:</p>
                <ol className="procedure reset">
                  <li>
                    <p>
                      Use the checkmark beside each shipment in the Close
                      Shipment table to add it to the list of shipments to be
                      closed.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["end-day-close-shipments-table"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      (optional) You can also use the checkmark at the top of
                      table to select all the shipments. You can then deselect
                      the ones that you do not wish to close.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["end-day-close-shipments-all-check-mark"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      You can either (1) Click the 'Close Selected' button to
                      close only the selected shipments, (2) Click the 'Close
                      All' button to close all the shipments listed in the Close
                      Shipments table.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["end-day-close-shipments-buttons"]}
                      alt="step"
                    />
                  </li>
                </ol>
                <p>
                  Shipments that are closed will be moved to the Closed
                  Shipments table which can be found on the close tab. You can
                  navigate to the closed shipments tab by using the button at
                  the top of the End of Day page.
                </p>
                <img
                  className={`img-fluid`}
                  style={{ width: "auto" }}
                  src={helpImages["end-day-close-shipment-tab"]}
                  alt="step"
                />
                <h4>Viewing and Reprinting End of Day Form</h4>
                <hr />
                <p>To view or reprint an end of day form:</p>
                <ol className="procedure reset">
                  <li>
                    <p>Click the closed section on the End of Day page.</p>
                    <img
                      className={`img-fluid`}
                      style={{
                        width: "auto",
                        maxWidth: "400px",
                        marginBottom: "20px",
                      }}
                      src={helpImages["end-day-close-shipment-tab-active"]}
                      alt="step"
                    />
                    <br />
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["end-day-closed-shipments-page"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Use the checkmark beside each shipment to select the ones
                      that you wish to view or print the End of Day form.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["end-day-close-shipment-tab-table"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      If you cannot find the shipment that you are looking for,
                      use the page navigation controls at the bottom of the
                      table to change pages. You can also use the filters at the
                      top of the table to filter by the closed date, ship date,
                      or carrier.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["end-day-close-shipment-page-controls"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      When you have selected the shipments for which you want to
                      view or print the End of Day forms, click the 'Print
                      Selected' button. You will then be able to view and print
                      the forms.
                    </p>
                    <img
                      className={`img-fluid`}
                      style={{ width: "auto" }}
                      src={helpImages["end-day-click-print-selected"]}
                      alt="step"
                    />
                  </li>
                </ol>
              </>
            ),
          },
        ],
      },
    ],
  },
  "carrier-integrations-overview": {
    title: "Carrier Integrations Overview",
    link: "carrier-integrations-overview",
    data: [
      {
        type: "directory",
        about: (
          <>
            <p>
              Click any one of the sub pages to visit the integrations page for
              the carrier.
            </p>
          </>
        ),
        subPages: getDirectorySupPages(fulfillmentPartners),
      },
    ],
  },
  "store-integrations-overview": {
    title: "Store Integrations Overview",
    link: "store-integrations-overview",
    data: [
      {
        type: "directory",
        about: (
          <>
            <p>
              Click any one of the sub pages to visit the integrations page for
              the sales channel.
            </p>
          </>
        ),
        subPages: getDirectorySupPages(technologyPartners),
      },
    ],
  },
  "create-label-shipments": {
    title: "How to Create a Label Within the Shipments Page in ShipVista",
    link: "create-label-shipments",
    data: [
      {
        type: "info",
        about:
          "Follow these instructions to create a label right from the Shipments page.",
        data: [
          {
            name: "Initial steps",
            subLink: "initial-steps",
            content: (
              <>
                <ol className="procedure">
                  <li>
                    <p>
                      Start by following the instructions on{" "}
                      <Link
                        href={
                          routes.help +
                          "/shipvista-help/manage-shipments/getting-rates-with-address"
                        }
                      >
                        this help page
                      </Link>{" "}
                      to get rates.
                    </p>
                  </li>
                </ol>
              </>
            ),
          },
          {
            name: "Select Service",
            subLink: "select-service",
            content: (
              <>
                <ol className="procedure">
                  <li>
                    <p>
                      After getting the rates for that shipment, select your
                      preferred carrier and service in the section at the
                      bottom.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={
                        helpImages[
                          "create-label-shipments-select-preffered-rate"
                        ]
                      }
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      When you select a carrier and service, a dialog will open
                      and show you the details of the shipment.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={
                        helpImages[
                          "create-label-shipments-shipment-details-dialog"
                        ]
                      }
                      alt="step"
                    />
                  </li>
                </ol>
              </>
            ),
          },
          {
            name: "Shipment Details",
            subLink: "shipment-details",
            content: (
              <>
                <ol className="procedure">
                  <li>
                    <p>
                      The top part of this dialog will show you everything
                      related to the shipment.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={
                        helpImages[
                          "create-label-shipments-shipments-details-zoomed"
                        ]
                      }
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>This includes the address details.</p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["create-label-shipments-address-details"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>The shipping items.</p>
                    <img
                      className={`img-fluid`}
                      src={
                        helpImages[
                          "create-label-shipments-shipping-items-details"
                        ]
                      }
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>And the service and rate details.</p>
                    <img
                      className={`img-fluid`}
                      src={
                        helpImages[
                          "create-label-shipments-service-and-rate-details"
                        ]
                      }
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      There is also an optional field to select any additional
                      add-ons for the shipments.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["create-label-shipments-add-ons-select"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      When you select an add-on, the cost will be updated in the
                      service and rate details section.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["create-label-shipments-add-ons-added"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      There is another optional field at the bottom of the
                      dialog for you to add customer references.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={
                        helpImages[
                          "create-label-shipments-customer-reference-no-filled"
                        ]
                      }
                      alt="step"
                    />
                  </li>
                </ol>
              </>
            ),
          },
          {
            name: "Final Steps",
            subLink: "final-steps",
            content: (
              <>
                <ol className="procedure">
                  <li>
                    <p>
                      When you select an add-on, the cost will be updated in the
                      service and rate details section.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["create-label-shipments-add-ons-added"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      There is another optional field at the bottom of the
                      dialog for you to add customer references.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={
                        helpImages[
                          "create-label-shipments-customer-reference-no-filled"
                        ]
                      }
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Besides the customer reference, you can select the paper
                      size.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={
                        helpImages["create-label-shipments-paper-size-select"]
                      }
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      When you are satisfied with the details, click 'Create
                      Label'.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={
                        helpImages["create-label-shipments-click-create-label"]
                      }
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>Another dialog will open and show you the label.</p>
                    <img
                      className={`img-fluid`}
                      src={
                        helpImages[
                          "create-label-shipments-shipping-label-dialog"
                        ]
                      }
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      You can print or download the label at the top right-hand
                      corner of the dialog.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["create-label-shipments-shipping-label"]}
                      alt="step"
                    />
                  </li>
                </ol>
              </>
            ),
          },
        ],
      },
    ],
  },
  "address-book": {
    title: "Address Book",
    link: "address-book",
    data: [
      {
        type: "info",
        about:
          "Once you have added an address into the address book, it will be available to use anywhere in the application.",
        data: [
          {
            name: "How to add Addresses",
            subLink: "add-addresses",
            content: (
              <>
                <ol className="procedure">
                  <li>
                    <p>
                      Start by logging into the dashboard of the application if
                      you haven't already.
                    </p>
                  </li>
                  <li>
                    <p>
                      Click the settings icon at the top of the page to go to
                      the account settings.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["address-book-select-settings"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      On the left-hand menu, click to expand the shipping panel.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["address-book-select-shipping"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Once the panel opens up, click 'Address Book' to open the
                      address book.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["address-book-select-address-book"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>On the page that opens, select 'Add New Address'.</p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["address-book-click-add-address"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      A dialog should open, prompting you to enter the address
                      details.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["address-book-enter-address-details"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      After entering the details of the address, click 'Save'.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["address-book-click-save"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      The new address should be added to the address book and
                      available to use throughout the application.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["address-book-address-added"]}
                      alt="step"
                    />
                  </li>
                </ol>
              </>
            ),
          },
        ],
      },
    ],
  },
  "air-miles": {
    title: "AIR MILES",
    link: "air-miles",
    data: [
      {
        type: "info",
        about:
          "If you have signed up for the AIR MILES Reward Miles program, you can take advantage of several offers that we have when you ship.",
        data: [
          {
            name: "How to add your collector card number",
            subLink: "add-card-number",
            content: (
              <>
                <ol className="procedure">
                  <li>
                    <p>
                      Start by logging into the dashboard of the application if
                      you haven't already.
                    </p>
                  </li>
                  <li>
                    <p>
                      Click your user profile icon at the top right hand corner
                      of the window.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["airmiles-step-1"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      Click the AIR MILES icon that appears in the popup on the
                      top right hand corner.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["airmiles-step-2"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      You will be presented with a page where you can see all
                      account-related AIR MILES settings.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["airmiles-step-3"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      On the page, you will see an AIR MILES collector card
                      image. Click the 'Connect your Account' button on the AIR
                      MILES card.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["airmiles-step-4"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      A dialog will open with a form to enter your AIR MILES
                      card details. Fill in the form.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["airmiles-step-5"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      After filling in the form with the correct details, click
                      the 'Connect' button to connect your AIR MILES account
                      with your ShipVista account.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["airmiles-step-6"]}
                      alt="step"
                    />
                  </li>
                  <li>
                    <p>
                      After clicking connect, the two accounts will be linked.
                      The AIR MILES card on the page will now show the updated
                      information.
                    </p>
                    <img
                      className={`img-fluid`}
                      src={helpImages["airmiles-step-7"]}
                      alt="step"
                    />
                  </li>
                </ol>
              </>
            ),
          },
        ],
      },
    ],
  },
  "subscriptions-faq": {
    title: "Subscription FAQs",
    link: "subscription-faq",
    data: [
      {
        type: "faq",
        data: (
          <>
            <FAQAccordion faqList={overview.en.faq} />
          </>
        ),
      },
    ],
  },
};
